@import "https://fonts.googleapis.com/css2?family=Readex+Pro:wght@700&family=Iceberg&family=Nunito:wght@200;300&display=swap";
*, :before, :after {
  box-sizing: border-box;
}

* {
  margin: 0;
}

html, body {
  height: 100%;
}

body {
  -webkit-font-smoothing: antialiased;
  line-height: 1.5;
}

img, picture, video, canvas, svg {
  max-width: 100%;
  display: block;
}

input, button, textarea, select {
  font: inherit;
}

p, h1, h2, h3, h4, h5, h6 {
  overflow-wrap: break-word;
}

#root, #__next {
  isolation: isolate;
}

.intro-mask {
  width: 100vw;
  height: 100vh;
  z-index: 15;
  opacity: 1;
  background-color: #1f1b3b;
  position: absolute;
}

.loading-wrapper {
  flex-direction: column;
  display: flex;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.loading-wrapper p {
  margin: 0 auto;
  font-size: 1.8rem;
  line-height: 1;
}

.lds-grid {
  width: 90px;
  height: 90px;
}

.lds-grid div {
  width: 20px;
  height: 20px;
  background: #1ef1bf;
  border-radius: 0%;
  animation: 1.2s linear infinite lds-grid;
  position: absolute;
}

.lds-grid div:nth-child(1) {
  animation-delay: 0s;
  top: 8px;
  left: 8px;
}

.lds-grid div:nth-child(2) {
  animation-delay: -.4s;
  top: 8px;
  left: 32px;
}

.lds-grid div:nth-child(3) {
  animation-delay: -.8s;
  top: 8px;
  left: 56px;
}

.lds-grid div:nth-child(4) {
  animation-delay: -.4s;
  top: 32px;
  left: 8px;
}

.lds-grid div:nth-child(5) {
  animation-delay: -.8s;
  top: 32px;
  left: 32px;
}

.lds-grid div:nth-child(6) {
  animation-delay: -1.2s;
  top: 32px;
  left: 56px;
}

.lds-grid div:nth-child(7) {
  animation-delay: -.8s;
  top: 56px;
  left: 8px;
}

.lds-grid div:nth-child(8) {
  animation-delay: -1.2s;
  top: 56px;
  left: 32px;
}

.lds-grid div:nth-child(9) {
  animation-delay: -1.6s;
  top: 56px;
  left: 56px;
}

@keyframes lds-grid {
  0%, 100% {
    opacity: 1;
  }

  50% {
    opacity: .4;
  }
}

.navbar {
  width: 100vw;
  z-index: 10;
  will-change: transform, opacity;
  padding: 6rem 3rem 3rem 6rem;
  transition: all .5s ease-in-out;
  position: fixed;
}

.navbar .nav-contents {
  font-family: Nunito, sans-serif;
  display: flex;
}

.navbar .nav-contents .nav-header {
  font-family: Iceberg, cursive;
  font-size: 2.4rem;
}

.navbar .nav-contents .nav-header a {
  flex-direction: row;
  text-decoration: none;
  display: flex;
}

.navbar .nav-contents .nav-header a .logo-left, .navbar .nav-contents .nav-header a .logo-right {
  color: #1ef1bf;
}

.navbar .nav-contents .nav-links {
  cursor: pointer;
  margin: 0 auto;
  padding-top: 3px;
  list-style-type: none;
  display: flex;
}

.navbar .nav-contents .nav-item {
  list-style-type: none;
}

.navbar .nav-contents .nav-item a {
  color: #dedde7;
  transform-origin: top;
  padding: 0 20px 0 28px;
  font-size: 2rem;
  text-decoration: none;
  list-style-type: none;
  transition: all .3s;
  animation: .5s ease-in-out forwards fadeIn;
}

.navbar .nav-contents .nav-item:hover a {
  color: #1ef1bf;
  padding: 0 24px;
}

.navbar .nav-contents .nav-item-phone {
  margin: 5px 0 0;
}

.navbar .nav-contents .language-select {
  width: 80px;
  padding-top: 4px;
}

.navbar .nav-contents .language-select a {
  color: #dedde7;
  font-size: 2rem;
  text-decoration: none;
}

.navbar .nav-contents .cta-btn {
  background-color: inherit;
  color: #1ef1bf;
  min-width: 180px;
  height: 36px;
  border: 0;
  border-radius: 8px;
  outline: 2px solid #1ef1bf;
  font-size: 2rem;
  font-weight: bold;
}

.navbar .nav-contents .cta-btn a {
  color: #dedde7;
  text-decoration: none;
}

.navbar .nav-contents button:hover {
  transition: all .3s;
  box-shadow: 0 0 14px #1ef1bf;
}

.navbar .nav-contents button:hover a {
  color: #1ef1bf;
  cursor: pointer;
}

.navbar-initial {
  background: linear-gradient(#070a11 0%, #0000 100%);
}

.navbar-alt {
  -webkit-backdrop-filter: blur(12px);
  -webkit-backdrop-filter: blur(12px);
  backdrop-filter: blur(12px);
  background-color: #1f1b3bb3;
  padding-top: 3rem;
}

.nav-toggle {
  visibility: hidden;
  color: #dedde7;
  text-shadow: 0 1px 10px #7b61ff;
}

.nav-toggle:hover {
  outline: 4px solid #1ef1bf;
  transform: rotate(180deg);
}

.nav-toggle i {
  font-size: 2.8rem;
}

.nav-lang-dropdown {
  margin-right: 2vw;
}

.nav-lang-dropdown .dropbtn {
  width: 80px;
  color: #dedde7;
  background-color: inherit;
  cursor: pointer;
  border: none;
  border-left: 2px solid #1ef1bf;
  outline: none;
  margin: 0 auto;
  padding: 3px 0;
  font-family: inherit;
  font-size: 2rem;
  transition: all .3s ease-in-out;
}

.dropdown-content {
  width: 80px;
  z-index: 1;
  background-color: #320096e6;
  padding: 3px 0 0 24px;
  display: none;
  position: absolute;
  box-shadow: 0 8px 16px #0003;
}

.dropdown-content a {
  float: none;
  color: #dedde7;
  font-size: 2rem;
  text-decoration: none;
  display: block;
}

.topnav a:hover, .nav-lang-dropdown:hover .dropbtn {
  color: #dedde7;
  background-color: #320096b3;
}

.dropdown-content a:hover {
  color: #1ef1bf;
  background-color: #320096;
}

.nav-lang-dropdown:hover .dropdown-content {
  display: block;
}

.dropdown-content:hover {
  border-left: 2px solid #1ef1bf;
  font-weight: bold;
}

.hide-container {
  visibility: hidden;
}

.show-container {
  visibility: visible;
}

.modal-overlay {
  -webkit-backdrop-filter: blur(12px);
  -webkit-backdrop-filter: blur(12px);
  backdrop-filter: blur(12px);
  width: 100vw;
  height: 100vh;
  z-index: 10;
  background-color: #7b61ffbf;
  margin: 0;
  padding: 0;
  position: absolute;
}

.mobile-logo {
  visibility: visible;
  color: #1ef1bf;
  text-shadow: 0 0 44px #00fc08b3;
  font-family: Iceberg, cursive;
  font-size: 3rem;
  text-decoration: none;
  position: absolute;
  top: 70px;
  right: 70px;
}

.mobile-logo a {
  flex-direction: row;
  text-decoration: none;
  display: flex;
}

.nav-mobile-language-select {
  display: none;
}

.nav-mobile-language-select a {
  font-size: 26px !important;
}

@media (max-width: 1200px) {
  .navbar .nav-contents .nav-item a {
    padding: 0 10px 0 12px;
  }

  .navbar .nav-contents .nav-item:hover a {
    padding: 0 11px;
  }

  .nav-item-phone {
    display: none;
  }
}

@media (max-width: 768px) {
  .navbar {
    visibility: hidden;
    -webkit-box-align: left;
    -ms-flex-align: left;
    align-items: left;
    padding: 0;
  }

  .navbar-alt {
    -webkit-backdrop-filter: none;
    -webkit-backdrop-filter: none;
    backdrop-filter: none;
  }

  .nav-contents .nav-header {
    display: none;
  }

  .nav-contents .nav-links {
    visibility: hidden;
    color: #1f1b3b;
    -webkit-box-align: left;
    -ms-flex-align: left;
    align-items: left;
    flex-direction: column;
    margin: 0;
    font-family: Readex Pro, Times, serif;
    position: fixed;
    top: 24vh;
  }

  .nav-contents .nav-links .nav-item {
    width: 80vw;
    text-align: left;
    margin-bottom: 3vh;
  }

  .nav-contents .nav-links .nav-item a {
    color: #1f1b3b;
    padding: 2rem;
    font-size: 8vw;
    transition: all .3s;
  }

  .nav-contents .nav-links .nav-item:hover a {
    background-color: #1f1b3b;
  }

  .nav-toggle {
    color: #dedde7;
    visibility: visible;
    background-color: inherit;
    cursor: pointer;
    border: 0;
    transition: all .8s;
    position: absolute;
    top: 70px;
    left: 50px;
  }

  .nav-toggle i {
    font-size: 6rem;
  }

  .cta-btn {
    display: none;
  }

  .nav-mobile-language-select {
    display: block;
  }
}

@media (min-width: 769px) {
  .mobile-logo {
    visibility: hidden;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: rotateX(-90deg);
  }

  50% {
    transform: rotateX(-20deg);
  }

  100% {
    opacity: 1;
    transform: rotateX(0);
  }
}

.pattern {
  position: absolute;
}

.pattern-1 {
  width: 6vw;
  top: 36vh;
  left: 14vw;
}

.pattern-1 img {
  opacity: .1;
  -webkit-filter: invert(40%) sepia(50%) saturate(4000%) hue-rotate(230deg) brightness() contrast();
  filter: invert(40%) sepia(50%) saturate(4000%) hue-rotate(230deg) brightness() contrast();
}

.pattern-2 {
  width: 4vw;
  top: 30vh;
  left: 7vw;
}

.pattern-2 img {
  opacity: .2;
  -webkit-filter: invert(40%) sepia(50%) saturate(4000%) hue-rotate(230deg) brightness() contrast();
  filter: invert(40%) sepia(50%) saturate(4000%) hue-rotate(230deg) brightness() contrast();
}

.pattern-3 {
  width: 6vw;
  top: 44vh;
  right: 7vw;
}

.pattern-3 img {
  opacity: .1;
  -webkit-filter: invert(40%) sepia(50%) saturate(4000%) hue-rotate(230deg) brightness() contrast();
  filter: invert(40%) sepia(50%) saturate(4000%) hue-rotate(230deg) brightness() contrast();
}

.pattern-4 {
  width: 8vw;
  top: 40vh;
  right: 14vw;
}

.pattern-4 img {
  opacity: .1;
  -webkit-filter: invert(40%) sepia(50%) saturate(4000%) hue-rotate(130deg) brightness() contrast();
  filter: invert(40%) sepia(50%) saturate(4000%) hue-rotate(130deg) brightness() contrast();
}

.pattern-5 {
  width: 7vw;
  top: 10vh;
  left: 10vw;
}

.pattern-5 img {
  opacity: .2;
  -webkit-filter: invert(40%) sepia(50%) saturate(4000%) hue-rotate(130deg) brightness() contrast();
  filter: invert(40%) sepia(50%) saturate(4000%) hue-rotate(130deg) brightness() contrast();
}

.pattern-6 {
  width: 8vw;
  top: 30rem;
  right: 10vw;
}

.pattern-6 img {
  opacity: .1;
  -webkit-filter: invert(40%) sepia(50%) saturate(4000%) hue-rotate(230deg) brightness() contrast();
  filter: invert(40%) sepia(50%) saturate(4000%) hue-rotate(230deg) brightness() contrast();
}

.pattern-7 {
  width: 6vw;
  top: 30%;
  right: 10vw;
}

.pattern-7 img {
  opacity: .1;
  -webkit-filter: invert(40%) sepia(50%) saturate(4000%) hue-rotate(230deg) brightness() contrast();
  filter: invert(40%) sepia(50%) saturate(4000%) hue-rotate(230deg) brightness() contrast();
}

.pattern-8 {
  width: 16vw;
  top: 20vh;
  left: 6vw;
}

.pattern-8 img {
  opacity: .1;
  -webkit-filter: invert(40%) sepia(50%) saturate(4000%) hue-rotate(230deg) brightness() contrast();
  filter: invert(40%) sepia(50%) saturate(4000%) hue-rotate(230deg) brightness() contrast();
}

.pattern-9 {
  width: 12vw;
  top: 26vh;
  right: 20vw;
}

.pattern-9 img {
  opacity: .3;
  -webkit-filter: invert(40%) sepia(50%) saturate(4000%) hue-rotate(230deg) brightness() contrast();
  filter: invert(40%) sepia(50%) saturate(4000%) hue-rotate(230deg) brightness() contrast();
}

.pattern-10 {
  width: 12vw;
  top: 10vh;
  right: 6vw;
}

.pattern-10 img {
  opacity: .1;
  -webkit-filter: invert(40%) sepia(50%) saturate(4000%) hue-rotate(230deg) brightness() contrast();
  filter: invert(40%) sepia(50%) saturate(4000%) hue-rotate(230deg) brightness() contrast();
}

.cookie-consent-container {
  z-index: 1;
  width: 180px;
  background: #22013dcc;
  border: 2px solid #7b61ff;
  border-radius: 8px;
  margin: 0 auto;
  padding: 8px;
  font-size: 1.4rem;
  display: none;
  position: fixed;
  bottom: 20px;
  right: 20px;
}

.cookie-consent-container .cookie-consent a {
  color: #fff;
  cursor: pointer;
  background-color: #050e2d;
  border: 1px solid #7b61ff;
  border-radius: 4px;
  padding: 5px;
  font-weight: bold;
  text-decoration: none;
}

.cookie-consent-container .cookie-consent .cookie-consent-buttons {
  margin: 10px 0;
}

.cookie-consent-container .cookie-consent .cookie-consent-buttons .cookie-consent-buttons--details {
  background-color: #f000;
  border: 1px solid #6006ce;
  font-weight: normal;
}

.section-hero {
  width: 100vw;
  height: 100vh;
  will-change: transform, opacity;
  background-image: url("hero-bkg1.adeff5ff.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.video-wrapper {
  position: absolute;
  top: 0;
  left: 0;
}

.video-wrapper video {
  height: 100vh;
  width: 100vw;
  -o-object-fit: cover;
  object-fit: cover;
  opacity: .5;
  transform: rotateY(180deg);
}

.hero-mask {
  -webkit-mask-image: linear-gradient(#000000b3, #0000);
  -webkit-mask-image: linear-gradient(#000000b3, #0000);
  mask-image: linear-gradient(#000000b3, #0000);
}

.hero-section-container {
  width: 100vw;
  height: 60vh;
  z-index: 2;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  display: flex;
}

#typewriter {
  font-size: 3rem;
}

#typewriter span {
  font-weight: 200;
}

.hero-copy {
  text-shadow: 0 10px 30px #00000030;
}

.hero-copy h1 {
  font-size: 110px !important;
}

.hero-asset {
  flex-direction: column;
  display: flex;
}

.hero-asset img {
  height: 26vw;
}

.arrow-down {
  width: 20rem;
  height: 10rem;
  -webkit-filter: drop-shadow(0 0 7px #0003);
  filter: drop-shadow(0 0 7px #0003);
  z-index: 2;
  margin: 80vh auto 0;
  position: absolute;
}

.arrow-down img {
  margin: 3rem auto;
  padding: 2rem 3rem;
  transition: all .3s;
}

.arrow-down img:hover {
  margin-top: 4rem;
  transition: all .3s;
}

@media (max-width: 1000px) {
  .hero-copy h1 {
    font-size: 100px !important;
  }

  .hero-asset {
    display: none;
  }
}

@media (max-width: 768px) {
  .hero-section-container {
    flex-direction: column-reverse;
    display: flex;
  }

  .hero-copy h1 {
    font-size: 80px !important;
  }
}

.section-services {
  will-change: transform, opacity;
  background: #dedde7;
}

.grid-parent {
  display: -ms-grid;
  -ms-grid-columns: (3fr) [3];
  -ms-grid-rows: (3fr) [3];
  grid-column-gap: 2rem;
  grid-row-gap: 2rem;
  max-width: 768px;
  grid-template-rows: repeat(3, 3fr);
  grid-template-columns: repeat(3, 3fr);
  padding: 0 0 2rem;
  display: grid;
}

.div1 {
  -ms-grid-row: 1;
  -ms-grid-row-span: 2;
  -ms-grid-column: 1;
  -ms-grid-column-span: 1;
  grid-area: 1 / 1 / 3 / 2;
}

.div2 {
  -ms-grid-row: 3;
  -ms-grid-row-span: 1;
  -ms-grid-column: 1;
  -ms-grid-column-span: 1;
  grid-area: 3 / 1 / 4 / 2;
}

.div3 {
  -ms-grid-row: 1;
  -ms-grid-row-span: 1;
  -ms-grid-column: 2;
  -ms-grid-column-span: 1;
  grid-area: 1 / 2 / 2 / 3;
}

.div4 {
  -ms-grid-row: 2;
  -ms-grid-row-span: 2;
  -ms-grid-column: 2;
  -ms-grid-column-span: 1;
  grid-area: 2 / 2 / 4 / 3;
}

.div5 {
  -ms-grid-row: 1;
  -ms-grid-row-span: 1;
  -ms-grid-column: 3;
  -ms-grid-column-span: 1;
  grid-area: 1 / 3 / 2 / 4;
}

.div6 {
  -ms-grid-row: 2;
  -ms-grid-row-span: 1;
  -ms-grid-column: 3;
  -ms-grid-column-span: 1;
  grid-area: 2 / 3 / 3 / 4;
}

.div7 {
  -ms-grid-row: 3;
  -ms-grid-row-span: 1;
  -ms-grid-column: 3;
  -ms-grid-column-span: 1;
  grid-area: 3 / 3 / 4 / 4;
}

.tile {
  opacity: .8;
  cursor: default;
  height: auto;
  background-color: #1ef1bf;
  background-size: cover;
  border-radius: 8px;
  flex-direction: column;
  transition: all .2s ease-in-out;
  display: flex;
}

.tile h2 {
  height: 20%;
  color: #dedde7;
  justify-content: center;
  align-items: flex-end;
  margin: 0 0 2rem;
  font-family: Readex Pro, Times, serif;
  font-size: 1.9rem;
  font-weight: bold;
  display: flex;
}

.tile p {
  height: 80%;
  text-align: center;
  opacity: .85;
  text-shadow: 0 0 6px #1f1b3b;
  justify-content: center;
  align-items: flex-end;
  padding: 1.4rem;
  font-size: 1.6rem;
  font-weight: bold;
  display: flex;
}

.tile:hover {
  opacity: 1;
  transform: scale(1.02);
}

.tile:hover h2 {
  color: #dedde7;
}

.tile:hover p {
  color: #dedde7;
  opacity: 1;
}

.square-tile {
  min-height: 220px;
}

.div1 {
  background-image: linear-gradient(#0b0bb4cc, #050e2d), url("tile_1.d0b07ae7.jpeg");
}

.div2 {
  background-image: linear-gradient(#0b0bb4cc, #050e2d), url("tile_2.556ac7bc.jpeg");
}

.div3 {
  background-image: linear-gradient(#0b0bb4cc, #050e2d), url("tile_3.260c8152.jpeg");
}

.div4 {
  background-image: linear-gradient(#0b0bb4cc, #050e2d), url("tile_4.b053f71b.jpeg");
}

.div5 {
  background-image: linear-gradient(#0b0bb4cc, #050e2d), url("tile_5.46835d82.jpeg");
}

.div6 {
  background-image: linear-gradient(#0b0bb4cc, #050e2d), url("tile_6.2cf31f55.jpeg");
}

.div7 {
  background-image: linear-gradient(#0b0bb4cc, #050e2d), url("tile_7.00f00b1c.jpeg");
}

@media (max-width: 1200px) {
  .tile p {
    opacity: .9;
    text-shadow: 0 0 4px #00000080;
  }
}

@media (max-width: 1000px) {
  .background-pattern {
    display: none;
  }

  .grid-parent {
    display: -ms-grid;
    -ms-grid-columns: (2fr) [2];
    -ms-grid-rows: (1fr) [5];
    grid-column-gap: 2rem;
    grid-row-gap: 2rem;
    grid-template-rows: repeat(5, 1fr);
    grid-template-columns: repeat(2, 2fr);
    display: grid;
  }

  .div1 {
    -ms-grid-row: 1;
    -ms-grid-row-span: 2;
    -ms-grid-column: 1;
    -ms-grid-column-span: 1;
    grid-area: 1 / 1 / 3 / 2;
  }

  .div2 {
    -ms-grid-row: 3;
    -ms-grid-row-span: 1;
    -ms-grid-column: 1;
    -ms-grid-column-span: 1;
    grid-area: 3 / 1 / 4 / 2;
  }

  .div3 {
    -ms-grid-row: 1;
    -ms-grid-row-span: 1;
    -ms-grid-column: 2;
    -ms-grid-column-span: 1;
    grid-area: 1 / 2 / 2 / 3;
  }

  .div4 {
    -ms-grid-row: 2;
    -ms-grid-row-span: 2;
    -ms-grid-column: 2;
    -ms-grid-column-span: 1;
    grid-area: 2 / 2 / 4 / 3;
  }

  .div5 {
    -ms-grid-row: 4;
    -ms-grid-row-span: 1;
    -ms-grid-column: 1;
    -ms-grid-column-span: 1;
    grid-area: 4 / 1 / 5 / 2;
  }

  .div6 {
    -ms-grid-row: 4;
    -ms-grid-row-span: 1;
    -ms-grid-column: 2;
    -ms-grid-column-span: 1;
    grid-area: 4 / 2 / 5 / 3;
  }

  .div7 {
    -ms-grid-row: 5;
    -ms-grid-row-span: 1;
    -ms-grid-column: 1;
    -ms-grid-column-span: 1;
    grid-area: 5 / 1 / 6 / 2;
  }

  .square-tile {
    height: 210px;
    border-radius: 8px;
  }

  .vertical-tile {
    height: 440px;
    border-radius: 8px;
  }
}

@media (max-width: 768px) {
  .tiles-container {
    padding: 0;
  }

  .grid-parent {
    flex-direction: column;
    display: flex;
  }

  .tile {
    opacity: .9;
  }

  .tile h2 {
    color: #1ef1bf;
    font-family: Readex Pro, Times, serif;
    font-size: 2.6rem;
    font-weight: 100;
  }

  .tile p {
    width: 20ch;
    color: #dedde7;
    margin: 0 auto;
    font-size: 2.2rem;
  }

  .tile h2, .tile p {
    text-shadow: 2px 2px 32px #1f1b3b;
  }

  .square-tile, .vertical-tile {
    width: 82vw;
    height: 300px;
  }
}

.section-why-go-online {
  will-change: transform;
  text-shadow: 0 0 28px #1f1b3b;
  background: url("bkg_why_online.036345b7.jpg") center / cover no-repeat;
}

.section-why-go-online h2 {
  letter-spacing: -.1rem;
  font-size: 2.4rem;
}

.section-why-go-online li {
  font-size: 1.8rem;
  list-style-type: none;
}

.section-why-go-online strong {
  color: #1ef1bf;
  font-weight: normal;
}

.section-why-go-online .background-glass {
  border: 3px solid #1f1b3b1a;
  padding: 4rem;
}

.section-why-go-online .background-glass .fa-caret-right {
  margin-right: 1rem;
}

@media (max-width: 768px) {
  .section-why-go-online {
    padding: 0;
  }

  .section-why-go-online .background-glass {
    max-width: 90vw;
    padding: 2rem;
  }

  .section-why-go-online .background-glass h2, .section-why-go-online .background-glass li {
    max-width: 60vw;
    padding-bottom: 2rem;
  }
}

.section-workflow {
  height: 70rem;
  background: #dedde7;
  position: relative;
}

.workflow-tiles {
  flex-direction: row;
  gap: 2rem;
  display: flex;
}

.workflow-tile {
  width: 280px;
  height: 280px;
  opacity: .8;
  border-radius: 8px;
  padding: 3rem;
  transition: all .2s ease-in-out;
}

.workflow-tile .workflow-tile-top {
  height: 50%;
  flex-direction: row;
  font-family: Nunito, serif;
  font-size: 8rem;
  font-weight: 100;
  display: flex;
}

.workflow-tile .workflow-tile-bottom {
  height: 50%;
}

.workflow-tile .workflow-tile-bottom h1 {
  margin-top: 1rem;
  font-family: Readex Pro, Times, serif;
  font-size: 2.6rem;
  font-weight: 400;
}

.workflow-tile .workflow-tile-bottom p {
  height: 50%;
  width: 100%;
  text-align: left;
  margin: 1rem 0 0;
  font-size: 1.6rem;
  line-height: 110%;
}

.workflow-tile .workflow-tile-icon {
  width: 5.6rem;
  height: 5.6rem;
  margin: 3rem 0 0 .8rem;
}

.workflow-tile .workflow-tile-icon img {
  justify-content: center;
  align-items: center;
}

.workflow-tile:hover {
  opacity: 1;
  transform: scale(1.02);
}

.workflow-tile:hover h2 {
  color: #7b61ff;
}

.workflow-tile:hover p {
  color: #dedde7;
}

.workflow-tile-1 {
  background-image: linear-gradient(to top, #0b0bb4cc, #050e2d), url("tile_6.2cf31f55.jpeg");
  background-size: 250%;
}

.workflow-tile-2 {
  background-image: linear-gradient(to top, #0b0bb4cc, #050e2d), url("tile_5.46835d82.jpeg");
}

.workflow-tile-3 {
  background-image: linear-gradient(to top, #0b0bb4cc, #050e2d), url("tile_1.d0b07ae7.jpeg");
  background-size: 220%;
}

@media (max-width: 1000px) {
  .section-workflow {
    height: 130rem;
    background: #dedde7;
    position: relative;
  }

  .workflow-tiles {
    flex-direction: column;
    gap: 2rem;
    margin: 0 auto;
    display: flex;
  }

  .workflow-tile {
    width: 600px;
    height: 280px;
  }
}

@media (max-width: 768px) {
  .workflow-tile {
    width: 80vw;
    height: 280px;
  }
}

.section-portfolio {
  height: calc($ slider-height100px);
  will-change: transform;
  background: #1f1b3b;
}

.portfolio-container {
  width: 50vw;
  height: auto;
  margin: 0 auto;
}

.slider {
  width: 360px;
  height: 680px;
  position: relative;
  top: 300px;
  left: 50%;
  transform: translate(-50%, -50%);
}

.slider a {
  text-decoration: none;
}

.wrapper {
  width: 360px;
  height: 680px;
  position: relative;
  overflow: hidden;
}

.slides {
  width: 10000px;
  display: flex;
  position: relative;
  top: 0;
  left: -360px;
}

.slides.shifting {
  transition: left .2s ease-out;
}

.slide {
  width: 360px;
  height: 680px;
  opacity: .8;
  flex-direction: column;
  justify-content: center;
  transition: all .7s;
  display: flex;
  position: relative;
}

.slide h2 {
  color: #1f1b3b;
  text-align: center;
  text-shadow: 0 1px 8px #fff3;
  padding: 0 30px;
  font-size: 2.1rem;
  transition: all .7s;
  position: relative;
  bottom: 12rem;
}

.slide:hover {
  opacity: 1;
}

.slide:hover h2 {
  color: #7b61ff;
}

.slider.loaded .slide:nth-child(2), .slider.loaded .slide:nth-child(7), .slider.loaded .slide:nth-child(1), .slider.loaded .slide:nth-child(6), .slider.loaded .slide:nth-child(3), .slider.loaded .slide:nth-child(4), .slider.loaded .slide:nth-child(5) {
  background: #fff0;
}

.control {
  width: 140px;
  height: 140px;
  z-index: 2;
  position: absolute;
  top: 50%;
}

.prev, .next {
  cursor: pointer;
  background-position: center;
  background-repeat: no-repeat;
  transition: all .3s ease-in-out;
}

.prev {
  background-image: url("arrow.471b96b5.svg");
  background-size: contain;
  left: -130px;
  transform: rotate(90deg);
}

.next {
  background-image: url("arrow.471b96b5.svg");
  background-size: contain;
  right: -130px;
  transform: rotate(270deg);
}

.prev:hover {
  left: -140px;
}

.next:hover {
  right: -140px;
}

@media (max-width: 1200px) {
  .portfolio-container {
    width: 70vw;
  }
}

@media (max-width: 1000px) {
  .portfolio-container {
    width: 80vw;
  }
}

@media (max-width: 500px) {
  .portfolio-container {
    width: 100vw;
  }

  .slide h2 {
    font-size: 3rem;
  }

  .prev {
    left: -10px;
    transform: rotate(90deg);
  }

  .next {
    right: -10px;
    transform: rotate(270deg);
  }

  .prev:hover {
    left: -16px;
  }

  .next:hover {
    right: -16px;
  }
}

.section-call-to-action {
  height: auto;
  background: #dedde7;
}

.section-contact-form {
  box-sizing: border-box;
  height: auto;
  background: url("bkg_contact-form.a5a5065c.jpg") center / cover no-repeat;
  padding: 0 0 12rem;
}

.section-contact-form .send-btn {
  color: #1f1b3b;
  cursor: pointer;
  background-color: #1ef1bf;
  border: none;
  border-radius: 8px;
  margin: 0 0 1rem 1rem;
  padding: 1rem 3rem;
  font-size: 2rem;
  font-weight: bold;
}

input, select, textarea {
  all: unset;
  color: #dedde7;
  border-left: 4px solid #1ef1bf;
  border-radius: 4px;
  margin: 1rem 0;
  padding: 1rem 2rem;
  font-size: 1.4rem;
}

input[type="text"]:focus, input[type="email"]:focus, textarea:focus {
  background: #101078;
  outline: 1px solid #64a0aa;
  transition: all .3s ease-in-out;
}

textarea {
  min-height: 12rem;
}

::-webkit-input-placeholder {
  color: #64c8a0;
  font-size: 1.3em;
}

:-ms-input-placeholder {
  color: #64c8a0;
  font-size: 1.3em;
}

::placeholder {
  color: #64c8a0;
  font-size: 1.3em;
}

.contact-methods {
  display: -ms-grid;
  -ms-grid-columns: (1fr) [2];
  -ms-grid-rows: (1fr) [2];
  grid-column-gap: 2rem;
  grid-row-gap: 2rem;
  grid-template-rows: repeat(2, 1fr);
  grid-template-columns: repeat(2, 1fr);
  display: grid;
}

.form-control {
  height: 3rem;
  width: 80%;
  background: #000514;
  margin-left: 1rem;
}

.contact1 {
  -ms-grid-row: 1;
  -ms-grid-row-span: 2;
  -ms-grid-column: 1;
  -ms-grid-column-span: 1;
  grid-area: 1 / 1 / 3 / 2;
}

.contact2 {
  -ms-grid-row: 1;
  -ms-grid-row-span: 1;
  -ms-grid-column: 2;
  -ms-grid-column-span: 1;
  grid-area: 1 / 2 / 2 / 3;
}

.contact3 {
  -ms-grid-row: 2;
  -ms-grid-row-span: 1;
  -ms-grid-column: 2;
  -ms-grid-column-span: 1;
  grid-area: 2 / 2 / 3 / 3;
}

.contact2, .contact3 {
  padding: 3rem;
}

.contact1, .contact2, .contact3 {
  border: 2px solid #c832ff1a;
}

h2 {
  color: #1ef1bf;
  font-size: 2.2rem;
}

h2 a {
  color: #1ef1bf;
}

.contact2 p, .contact3 p {
  font-family: Readex Pro, Times, serif;
  font-size: 2.8rem;
}

.contact2 a, .contact3 a {
  color: #dedde7;
}

.contact2 img, .contact3 img {
  width: 260px;
}

@media (max-width: 1000px) {
  .contact-methods {
    flex-direction: column;
    display: flex;
  }
}

@media (max-width: 768px) {
  .section-contact-form {
    padding: 0 0 6rem;
  }

  .contact-methods {
    flex-direction: column;
    display: flex;
  }

  .contact2 h2, .contact3 h2 {
    font-size: 2rem;
  }

  .contact2 p, .contact2 a, .contact3 p, .contact3 a {
    font-size: 2.4rem;
  }
}

.section-footer {
  height: auto;
  background: #dedde7;
  padding: 4rem 0 1rem;
}

.section-footer .footer-logo {
  color: #1f1b3b;
  font-family: Iceberg, cursive;
  font-size: 2.1rem;
}

.section-footer h1 {
  color: #1f1b3b;
  padding: 0 0 .8rem;
  font-size: 2.2rem;
}

.section-footer li {
  list-style-type: none;
}

.section-footer li a {
  color: #7b61ff;
  font-size: 1.8rem;
  text-decoration: none;
}

.section-footer p {
  color: #7b61ff;
  font-size: 1.8rem;
}

.section-footer p a {
  color: #7b61ff;
  text-decoration: none;
}

.footer-container {
  flex-direction: row;
  justify-content: space-between;
  display: flex;
}

.footer-social {
  flex-direction: row;
  display: flex;
}

.footer-social img {
  width: 4rem;
  height: 4rem;
  margin: 2rem 1rem 0 0;
}

.copyright {
  color: #1f1b3b;
  text-align: center;
  opacity: .5;
  padding: 2rem 0 0;
  font-size: 1.4rem;
  font-weight: bold;
}

@media (max-width: 1000px) {
  .footer-container {
    display: -ms-grid;
    -ms-grid-columns: (1fr) [2];
    grid-template-columns: repeat(2, 1fr);
    display: grid;
  }

  .footer-col-1 {
    -ms-grid-row: 1;
    -ms-grid-row-span: 1;
    -ms-grid-column: 1;
    -ms-grid-column-span: 1;
    grid-area: 1 / 1 / 2 / 2;
    padding: 0 0 4rem;
  }

  .footer-col-2 {
    -ms-grid-row: 2;
    -ms-grid-row-span: 1;
    -ms-grid-column: 1;
    -ms-grid-column-span: 1;
    grid-area: 2 / 1 / 3 / 2;
  }

  .footer-col-3 {
    -ms-grid-row: 2;
    -ms-grid-row-span: 1;
    -ms-grid-column: 2;
    -ms-grid-column-span: 1;
    grid-area: 2 / 2 / 3 / 3;
  }
}

@media (max-width: 768px) {
  .footer-container {
    flex-direction: column;
    display: flex;
  }

  .footer-column {
    padding: 0 0 4rem;
  }
}

.contact-thank-you {
  padding: 16vh 0;
}

.privacy-policy {
  padding-top: 10vh;
}

.privacy-policy h3 {
  margin: 3rem 0 1rem;
  font-size: 2rem;
}

.privacy-policy p {
  font-size: 1.8rem;
  line-height: 120%;
}

.privacy-policy input {
  cursor: pointer;
  outline: 1px solid #1ef1bf;
  margin: 5rem 0;
}

.case-studies {
  padding: 20rem 0 0;
}

.case-study-container {
  height: auto;
  width: 80vw;
  border-radius: 1.2rem;
  margin: 0 auto;
  box-shadow: 0 10px 64px #0000001a;
}

.case-study-header {
  height: 50rem;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 1.2rem;
}

.case-study-header-nav {
  position: relative;
}

.header-nav-arrows {
  width: 90%;
  flex-direction: row;
  gap: 60vw;
  display: flex;
  position: absolute;
  top: 36rem;
  left: 50%;
  transform: translate(-50%);
}

.header-nav-arrows .portfolio-nav-prev {
  transform: rotate(90deg);
}

.header-nav-arrows .portfolio-nav-prev img {
  height: 4rem;
}

.header-nav-arrows .portfolio-nav-next {
  transform: rotate(270deg);
}

.header-nav-arrows .portfolio-nav-next img {
  height: 4rem;
}

.case-study-title {
  margin-left: 50%;
}

.case-study-title h1 {
  text-shadow: 0 1px 18px #18005080;
}

.case-study-description {
  width: 40vw;
  text-align: justify;
  padding-right: 8rem;
}

.case-study-description .see-live-btn a {
  color: #1ef1bf;
  background-color: #1f1b3b;
  border-radius: 1rem;
  padding: 1rem;
  font-family: Nunito, serif;
  font-size: 2rem;
  font-weight: bold;
  text-decoration: none;
  transition: all .3s;
}

.case-study-description .see-live-btn a:hover {
  color: #7b61ff;
}

.case-study-description .see-live-btn a:visited {
  color: #dedde7;
}

.project-scope-section {
  max-height: 80rem;
}

.project-visualization-pc {
  display: visible !important;
}

.project-visualization-mobile {
  display: none !important;
}

.tech-section, .design-section {
  height: auto;
}

.bright-section {
  background: #dedde7;
  flex-direction: row;
  padding: 4rem 8rem;
  display: flex;
}

.bright-section .case-study-design img {
  width: 40vw;
  max-width: unset;
  border-radius: 2rem;
  margin: 0 auto;
  top: 4rem;
  right: 4rem;
  box-shadow: 8px 8px 24px #28283c4d;
}

.bright-section .case-study-description p {
  color: #1f1b3b;
  font-size: 2rem;
  line-height: 126%;
}

.dark-section {
  background: #383250;
  flex-direction: row;
  padding: 4rem 8rem;
  display: flex;
}

.dark-section .case-study-description p {
  color: #dedde7;
  font-size: 2.2rem;
}

.dark-section .case-study-description-title h1 {
  font-size: 5rem;
}

.dark-section .tech-stack-logos {
  flex-direction: column;
  padding-right: 8rem;
  display: flex;
}

.dark-section .tech-stack-logos .tech-stack-logos-row {
  flex-direction: row;
  display: flex;
}

.dark-section .tech-stack-logos img {
  width: 5vw;
  height: 5vw;
  -webkit-filter: contrast(200%) grayscale(90%);
  filter: contrast(200%) grayscale(90%);
  margin: 1rem;
  transition: all .3s ease-in-out;
}

.dark-section .tech-stack-logos img:hover {
  -webkit-filter: unset;
  filter: unset;
}

.dark-section .tech-stack-logos a {
  background-color: #331a5d;
  border-radius: 1rem;
  margin-bottom: 1rem;
}

.dark-section .tech-stack-logos a:hover {
  color: #7b61ff;
  background-color: inherit;
}

.dark-section .tech-stack-logos a:visited {
  color: #dedde7;
}

.color-palette-wrapper {
  width: 26vw;
  height: 20rem;
  display: -ms-grid;
  -ms-grid-columns: (fr) [1];
  grid-template-columns: repeat(1, fr);
  -ms-grid-rows: (1fr) [2];
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  grid-template-rows: repeat(2, 1fr);
  display: grid;
}

.color-palette-wrapper .color-palette {
  border-radius: 8px;
  box-shadow: 8px 8px 24px #28283c14;
}

.color-palette-wrapper .color-palette-1 {
  -ms-grid-row: 1;
  -ms-grid-row-span: 1;
  -ms-grid-column: 1;
  -ms-grid-column-span: 1;
  background-color: #6ca95e;
  grid-area: 1 / 1 / 2 / 2;
}

.color-palette-wrapper .color-palette-2 {
  -ms-grid-row: 1;
  -ms-grid-row-span: 1;
  -ms-grid-column: 2;
  -ms-grid-column-span: 1;
  background-color: #fcfffc;
  grid-area: 1 / 2 / 2 / 3;
}

.color-palette-wrapper .color-palette-3 {
  -ms-grid-row: 2;
  -ms-grid-row-span: 1;
  -ms-grid-column: 1;
  -ms-grid-column-span: 1;
  background-color: #1d3218;
  grid-area: 2 / 1 / 3 / 2;
}

.color-palette-wrapper .color-palette-4 {
  -ms-grid-row: 2;
  -ms-grid-row-span: 1;
  -ms-grid-column: 2;
  -ms-grid-column-span: 1;
  background-color: #ebf840;
  grid-area: 2 / 2 / 3 / 3;
}

.color-palette-wrapper p {
  text-transform: uppercase;
  opacity: .6;
  padding: 5rem 0 0 2rem;
}

.color-palette-wrapper .color-palette-3 p {
  color: #dedde7;
}

.typography-wrapper {
  width: 18vw;
  padding: 6rem 0;
}

.typography-wrapper img {
  width: auto;
  opacity: .9;
  padding: 2rem 0;
}

.mobile-section {
  height: auto;
  background-image: url("background_mobile_view.d3142f3f.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: none;
}

.mobile-view img {
  height: 70vh;
  margin: 0 auto;
}

.mobile-background-decal p {
  text-shadow: 0 0 20px #2828464d;
  font-family: Iceberg, cursive;
  line-height: 70%;
  color: #00000012 !important;
  font-size: 12vw !important;
}

.portfolio-navigation {
  width: inherit;
  height: 20rem;
  height: 0;
  padding: 0;
}

.portfolio-nav-arrows {
  flex-direction: row;
  gap: 20vw;
  display: flex;
  position: relative;
  bottom: 18rem;
  left: 50%;
  transform: translate(-50%);
}

.portfolio-nav-arrows .portfolio-nav-prev {
  transform: rotate(90deg);
}

.portfolio-nav-arrows .portfolio-nav-prev img {
  height: 4rem;
}

.portfolio-nav-arrows .portfolio-nav-next {
  transform: rotate(270deg);
}

.portfolio-nav-arrows .portfolio-nav-next img {
  height: 4rem;
}

@media (max-width: 1400px) and (min-width: 1000px) {
  .project-scope-section {
    min-height: 100rem;
  }
}

@media (min-width: 1400px) {
  .portfolio-filler {
    height: 0;
  }
}

@media (min-width: 1600px) {
  .case-study-header {
    height: 60rem;
  }

  .portfolio-filler {
    height: 20rem;
  }
}

@media (min-width: 1800px) {
  .portfolio-filler {
    height: 44rem;
  }
}

@media (min-width: 2000px) {
  .portfolio-filler {
    height: 130rem;
  }

  .mobile-background-decal {
    margin-top: 30vh;
  }

  .bright-section .case-study-design img {
    height: 3474px;
    width: 1000px;
  }

  .dark-section .tech-stack-logos img {
    width: 10rem;
    height: 10rem;
    -webkit-filter: contrast(200%) grayscale(90%);
    filter: contrast(200%) grayscale(90%);
    margin: 1rem;
    transition: all .3s ease-in-out;
  }
}

@media (max-width: 1800px) {
  .mobile-background-decal {
    display: none;
  }
}

@media (max-width: 1200px) {
  .case-study-title {
    margin-left: 10%;
  }

  .portfolio-navigation {
    width: inherit;
    height: 10rem;
    padding: 0;
  }

  .portfolio-nav-arrows {
    flex-direction: row;
    gap: 20vw;
    display: flex;
    position: absolute;
    bottom: 7rem;
    left: 50%;
    transform: translate(-50%);
  }
}

@media (max-width: 1000px) {
  .case-study-description {
    width: 70vw;
    padding-right: 1rem;
  }

  .design-section .case-study-description {
    width: 100%;
  }

  .color-palette-wrapper {
    width: 70vw;
  }

  .case-study-container {
    width: 95vw;
  }

  .project-visualization-pc {
    display: none !important;
  }

  .project-visualization-mobile {
    display: inline !important;
  }

  .project-visualization-mobile .case-study-design img {
    width: 90%;
    border-radius: 8px;
    margin: 8rem auto;
    box-shadow: 0 10px 64px #683befd8;
  }

  .dark-section .tech-stack-logos img {
    width: 6rem;
    height: 6rem;
    -webkit-filter: contrast(200%) grayscale(90%);
    filter: contrast(200%) grayscale(90%);
    margin: 1rem;
    transition: all .3s ease-in-out;
  }

  .typography-wrapper {
    width: 40vw;
  }

  .mobile-view {
    position: absolute;
  }

  .mobile-view img {
    width: 120%;
  }
}

@media (max-width: 768px) {
  .header-nav-arrows {
    gap: 40vw;
    margin: 1rem;
  }

  .bright-section {
    padding-left: 4rem;
  }

  .bright-section .case-study-description-title h1 {
    font-size: 9vw;
  }

  .dark-section {
    padding-left: 4rem;
  }

  .dark-section .case-study-description-title h1 {
    font-size: 9vw;
  }

  .leading-text {
    max-width: 80vw;
    padding-top: 14rem;
  }

  .case-study-header {
    background-position: 25%;
    background-size: cover;
  }
}

html.has-scroll-smooth {
  overflow: hidden;
}

html.has-scroll-dragging {
  -webkit-user-select: none;
  user-select: none;
}

.has-scroll-smooth body {
  overflow: hidden;
}

.has-scroll-smooth [data-scroll-container] {
  min-height: 100vh;
}

[data-scroll-direction="horizontal"] [data-scroll-container] {
  height: 100vh;
  white-space: nowrap;
  display: inline-block;
}

[data-scroll-direction="horizontal"] [data-scroll-section] {
  vertical-align: top;
  white-space: nowrap;
  height: 100%;
  display: inline-block;
}

.c-scrollbar {
  width: 11px;
  height: 100%;
  transform-origin: 100%;
  opacity: 0;
  transition: transform .3s, opacity .3s, -webkit-transform .3s;
  position: absolute;
  top: 0;
  right: 0;
}

.c-scrollbar:hover {
  transform: scaleX(1.45);
}

.c-scrollbar:hover, .has-scroll-scrolling .c-scrollbar, .has-scroll-dragging .c-scrollbar {
  opacity: 1;
}

[data-scroll-direction="horizontal"] .c-scrollbar {
  width: 100%;
  height: 10px;
  top: auto;
  bottom: 0;
  transform: scaleY(1);
}

[data-scroll-direction="horizontal"] .c-scrollbar:hover {
  transform: scaleY(1.3);
}

.c-scrollbar_thumb {
  opacity: .5;
  width: 7px;
  cursor: -webkit-grab;
  cursor: grab;
  background-color: #000;
  border-radius: 10px;
  margin: 2px;
  position: absolute;
  top: 0;
  right: 0;
}

.has-scroll-dragging .c-scrollbar_thumb {
  cursor: -webkit-grabbing;
  cursor: grabbing;
}

[data-scroll-direction="horizontal"] .c-scrollbar_thumb {
  bottom: 0;
  right: auto;
}

html {
  font-size: 62.5%;
}

body {
  color: #dedde7;
  background: #1f1b3b;
  margin: 0;
  padding: 0;
  font-family: Nunito, serif;
  overflow-x: hidden;
}

.section-container {
  width: 50vw;
  max-width: 160rem;
  margin: 0 auto;
  padding: 0 0 3rem;
}

.rounded-corners {
  border-radius: 8px;
}

.heading-font {
  letter-spacing: -.16rem;
  max-width: 90vw;
  font-family: Readex Pro, Times, serif;
  font-size: 4.8rem;
  font-weight: normal;
  line-height: 90%;
}

.heading-font strong {
  color: #1ef1bf;
  font-weight: normal;
}

.heading-dark {
  color: #1f1b3b;
}

.heading-dark strong {
  color: #7b61ff;
  font-weight: normal;
}

.leading-text {
  max-width: 90ch;
  padding: 8rem 0 6rem;
  display: flex;
}

.leading-text .accent {
  min-width: 1rem;
  border-radius: 8px;
  margin-right: 4rem;
}

.leading-text .accent-primary {
  background-color: #1ef1bf;
}

.leading-text .accent-secondary {
  background-color: #7b61ff;
}

.background-glass {
  -webkit-backdrop-filter: blur(12px);
  -webkit-backdrop-filter: blur(12px);
  backdrop-filter: blur(12px);
  background-color: #7b61ff33;
}

.custom-fade-in {
  opacity: 1;
  transition: opacity 1s;
}

.custom-fade-out {
  opacity: 0;
  transition: opacity 1s;
}

.scroll-class {
  opacity: 1;
}

@media (max-width: 1200px) {
  .section-container {
    width: 80vw;
    padding: 0 0 4rem 2rem;
  }

  .pattern {
    display: none;
  }

  .leading-text {
    max-width: 70vw;
  }
}

@media (max-width: 768px) {
  .section-container {
    padding: 0;
  }

  .heading-font {
    font-size: 4rem;
    line-height: 1;
  }
}

/*# sourceMappingURL=400.48e682f8.css.map */
