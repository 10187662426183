.section-workflow {
    position: relative;
    background: $offWhite;
    height: 70rem;
}

.workflow-tiles {
    display: flex;
    flex-direction: row;
    gap: 2rem;
}

.workflow-tile {
    width: 280px;
    height: 280px;
    border-radius: $border-radius;
    opacity: .8;
    transition: all .2s ease-in-out;

    padding: 3rem;

    .workflow-tile-top {
        display: flex;
        flex-direction: row;
        height: 50%;
        font-size: 8rem;
        font-family: $paragraphFont;
        font-weight: 100;
    }

    .workflow-tile-bottom {
        height: 50%;

        h1 {
            font-family: $headingFont;
            font-weight: 400;
            font-size: 2.6rem;
            margin-top: 1rem;
        }

        p {
            height: 50%;
            width: 100%;
            font-size: 1.6rem;
            line-height: 110%;
            text-align: left;
            margin: 1rem 0 0 0;
        }
    }

    .workflow-tile-icon {
        width: 5.6rem;
        height: 5.6rem;
        margin: 3rem 0 0 .8rem;

        img {
        align-items: center;
        justify-content: center;
        }
    }

}

.workflow-tile:hover {
    transform: scale(1.02);
    opacity: 1;

    h2 {
        color: $secondaryColor;
    }

    p {
        color: $offWhite;
    }
}

.workflow-tile-1 {
    background-image: linear-gradient(to top, $gradientSecondaryStart, $gradientSecondaryEnd), url("../img/tile_6.jpeg");
    background-size: 250%;
}

.workflow-tile-2 {
    background-image: linear-gradient(to top, $gradientSecondaryStart, $gradientSecondaryEnd), url("../img/tile_5.jpeg");    
}

.workflow-tile-3 {
    background-image: linear-gradient(to top, $gradientSecondaryStart, $gradientSecondaryEnd), url("../img/tile_1.jpeg");
    background-size: 220%;
}

@media (max-width: 1000px) {
    .section-workflow {
        position: relative;
        background: $offWhite;

        height: 130rem;
    }

    .workflow-tiles {
        display: flex;
        flex-direction: column;
        gap: 2rem;
        margin: 0 auto;
    }

    .workflow-tile {
        width: 600px;
        height: 280px;
    }
}

@media (max-width: 768px) {
    .workflow-tile {
        width: 80vw;
        height: 280px;
    }
}