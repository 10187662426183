// fonts import @import url('https://fonts.googleapis.com/css2?family=Archivo+Black&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Readex+Pro:wght@700&family=Iceberg&family=Nunito:wght@200;300&display=swap');

// @import url('https://fonts.googleapis.com/css2?family=Dela+Gothic+One&family=Iceberg&family=Nunito:wght@200;300&display=swap');

// @font-face {
//     font-family: 'Dela Gothic One';
//     font-style: normal;
//     font-weight: normal;
//     src: url('./fonts/DelaGothicOne-Regular.ttf');
// }

// fonts variable names
$headingFont: 'Readex Pro', Times, serif;
$paragraphFont: 'Nunito', serif;

// solid colors
$offBlack: #1f1b3b;
$offWhite: #dedde7;
$primaryColor: #1EF1BF;
$secondaryColor: #7B61FF;
$transparentColor: #ff000000;

// gradients
$gradientPrimaryStart: #00fabb;
$gradientPrimaryEnd: #00ffc838;

$gradientSecondaryStart: rgba(11, 11, 180, 0.8);
$gradientSecondaryEnd: rgb(5, 14, 45);

// blur effects
$standardBlur: 12px;

// border styles
$border-radius: 8px;

// transitions
$transition-default: all .3s ease-in-out;

// fonts
$smallParagraph: 1.8rem;
