/*
  Josh's Custom CSS Reset
  https://www.joshwcomeau.com/css/custom-css-reset/
*/
@import url("https://fonts.googleapis.com/css2?family=Readex+Pro:wght@700&family=Iceberg&family=Nunito:wght@200;300&display=swap");
*, *::before, *::after {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

* {
  margin: 0;
}

html, body {
  height: 100%;
}

body {
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
}

img, picture, video, canvas, svg {
  display: block;
  max-width: 100%;
}

input, button, textarea, select {
  font: inherit;
}

p, h1, h2, h3, h4, h5, h6 {
  overflow-wrap: break-word;
}

#root, #__next {
  isolation: isolate;
}

.intro-mask {
  background-color: #1f1b3b;
  position: absolute;
  width: 100vw;
  height: 100vh;
  z-index: 15;
  opacity: 1;
}

.loading-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.loading-wrapper p {
  font-size: 1.8rem;
  line-height: 1;
  margin: 0 auto;
}

.lds-grid {
  width: 90px;
  height: 90px;
}

.lds-grid div {
  position: absolute;
  width: 20px;
  height: 20px;
  border-radius: 0%;
  background: #1EF1BF;
  -webkit-animation: lds-grid 1.2s linear infinite;
          animation: lds-grid 1.2s linear infinite;
}

.lds-grid div:nth-child(1) {
  top: 8px;
  left: 8px;
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
}

.lds-grid div:nth-child(2) {
  top: 8px;
  left: 32px;
  -webkit-animation-delay: -0.4s;
          animation-delay: -0.4s;
}

.lds-grid div:nth-child(3) {
  top: 8px;
  left: 56px;
  -webkit-animation-delay: -0.8s;
          animation-delay: -0.8s;
}

.lds-grid div:nth-child(4) {
  top: 32px;
  left: 8px;
  -webkit-animation-delay: -0.4s;
          animation-delay: -0.4s;
}

.lds-grid div:nth-child(5) {
  top: 32px;
  left: 32px;
  -webkit-animation-delay: -0.8s;
          animation-delay: -0.8s;
}

.lds-grid div:nth-child(6) {
  top: 32px;
  left: 56px;
  -webkit-animation-delay: -1.2s;
          animation-delay: -1.2s;
}

.lds-grid div:nth-child(7) {
  top: 56px;
  left: 8px;
  -webkit-animation-delay: -0.8s;
          animation-delay: -0.8s;
}

.lds-grid div:nth-child(8) {
  top: 56px;
  left: 32px;
  -webkit-animation-delay: -1.2s;
          animation-delay: -1.2s;
}

.lds-grid div:nth-child(9) {
  top: 56px;
  left: 56px;
  -webkit-animation-delay: -1.6s;
          animation-delay: -1.6s;
}

@-webkit-keyframes lds-grid {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0.4;
  }
}

@keyframes lds-grid {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0.4;
  }
}

.navbar {
  width: 100vw;
  position: fixed;
  z-index: 10;
  padding: 6rem 3rem 3rem 6rem;
  -webkit-transition: ease-in-out .5s;
  transition: ease-in-out .5s;
  will-change: transform, opacity;
}

.navbar .nav-contents {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-family: 'Nunito', sans-serif;
}

.navbar .nav-contents .nav-header {
  font-family: 'Iceberg', cursive;
  font-size: 2.4rem;
}

.navbar .nav-contents .nav-header a {
  text-decoration: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
}

.navbar .nav-contents .nav-header a .logo-left {
  color: #1EF1BF;
}

.navbar .nav-contents .nav-header a .logo-right {
  color: #1EF1BF;
}

.navbar .nav-contents .nav-links {
  list-style-type: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 0 auto;
  padding-top: 3px;
  cursor: pointer;
}

.navbar .nav-contents .nav-item {
  list-style-type: none;
}

.navbar .nav-contents .nav-item a {
  color: #dedde7;
  padding: 0 20px 0 28px;
  font-size: 2rem;
  text-decoration: none;
  -webkit-animation: fadeIn 500ms ease-in-out forwards;
          animation: fadeIn 500ms ease-in-out forwards;
  -webkit-transform-origin: top center;
          transform-origin: top center;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  list-style-type: none;
}

.navbar .nav-contents .nav-item:hover a {
  color: #1EF1BF;
  padding: 0px 24px 0px 24px;
}

.navbar .nav-contents .nav-item-phone {
  margin: 5px 0 0;
}

.navbar .nav-contents .language-select {
  width: 80px;
  padding-top: 4px;
}

.navbar .nav-contents .language-select a {
  font-size: 2rem;
  text-decoration: none;
  color: #dedde7;
}

.navbar .nav-contents .cta-btn {
  font-weight: bold;
  background-color: inherit;
  color: #1EF1BF;
  font-size: 2rem;
  min-width: 180px;
  height: 36px;
  border: 0;
  outline: 2px solid #1EF1BF;
  border-radius: 8px;
}

.navbar .nav-contents .cta-btn a {
  text-decoration: none;
  color: #dedde7;
}

.navbar .nav-contents button:hover {
  -webkit-box-shadow: 0px 0px 14px #1EF1BF;
          box-shadow: 0px 0px 14px #1EF1BF;
  -webkit-transition: .3s;
  transition: .3s;
}

.navbar .nav-contents button:hover a {
  color: #1EF1BF;
  cursor: pointer;
}

.navbar-initial {
  background: -webkit-gradient(linear, left top, left bottom, from(#070a11), to(rgba(0, 0, 0, 0)));
  background: linear-gradient(180deg, #070a11 0%, rgba(0, 0, 0, 0) 100%);
}

.navbar-alt {
  padding-top: 3rem;
  background-color: rgba(31, 27, 59, 0.7);
  -webkit-backdrop-filter: blur(12px);
          backdrop-filter: blur(12px);
}

.nav-toggle {
  visibility: hidden;
  color: #dedde7;
  text-shadow: 0px 1px 10px #7B61FF;
}

.nav-toggle:hover {
  outline: 4px solid #1EF1BF;
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}

.nav-toggle i {
  font-size: 2.8rem;
}

.nav-lang-dropdown {
  margin-right: 2vw;
}

.nav-lang-dropdown .dropbtn {
  width: 80px;
  font-size: 2rem;
  border: none;
  outline: none;
  color: #dedde7;
  padding: 3px 0;
  margin: 0 auto;
  background-color: inherit;
  font-family: inherit;
  border-left: 2px solid #1EF1BF;
  cursor: pointer;
  -webkit-transition: ease-in-out .3s;
  transition: ease-in-out .3s;
}

.dropdown-content {
  display: none;
  position: absolute;
  width: 80px;
  background-color: rgba(50, 0, 150, 0.9);
  -webkit-box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
          box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  padding: 3px 0 0 24px;
}

.dropdown-content a {
  float: none;
  color: #dedde7;
  font-size: 2rem;
  text-decoration: none;
  display: block;
}

.topnav a:hover, .nav-lang-dropdown:hover .dropbtn {
  background-color: rgba(50, 0, 150, 0.7);
  color: #dedde7;
}

.dropdown-content a:hover {
  background-color: #320096;
  color: #1EF1BF;
}

.nav-lang-dropdown:hover .dropdown-content {
  display: block;
}

.dropdown-content:hover {
  border-left: 2px solid #1EF1BF;
  font-weight: bold;
}

.hide-container {
  visibility: hidden;
}

.show-container {
  visibility: visible;
}

.modal-overlay {
  background-color: rgba(123, 97, 255, 0.75);
  -webkit-backdrop-filter: blur(12px);
          backdrop-filter: blur(12px);
  position: absolute;
  width: 100vw;
  height: 100vh;
  margin: 0;
  padding: 0;
  z-index: 10;
}

.mobile-logo {
  position: absolute;
  visibility: visible;
  right: 70px;
  top: 70px;
  font-family: 'Iceberg', cursive;
  font-size: 3rem;
  text-decoration: none;
  color: #1EF1BF;
  text-shadow: 0px 0px 44px rgba(0, 252, 8, 0.7);
}

.mobile-logo a {
  text-decoration: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
}

.nav-mobile-language-select {
  display: none;
}

.nav-mobile-language-select a {
  font-size: 26px !important;
}

@media (max-width: 1200px) {
  .navbar .nav-contents .nav-item a {
    padding: 0px 10px 0px 12px;
  }
  .navbar .nav-contents .nav-item:hover a {
    padding: 0px 11px 0px 11px;
  }
  .nav-item-phone {
    display: none;
  }
}

@media (max-width: 768px) {
  .navbar {
    visibility: hidden;
    -webkit-box-align: left;
        -ms-flex-align: left;
            align-items: left;
    padding: 0;
  }
  .navbar-alt {
    -webkit-backdrop-filter: none;
            backdrop-filter: none;
  }
  .nav-contents .nav-header {
    display: none;
  }
  .nav-contents .nav-links {
    visibility: hidden;
    color: #1f1b3b;
    font-family: "Readex Pro", Times, serif;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: left;
        -ms-flex-align: left;
            align-items: left;
    position: fixed;
    margin: 0;
    top: 24vh;
  }
  .nav-contents .nav-links .nav-item {
    margin-bottom: 3vh;
    width: 80vw;
    text-align: left;
  }
  .nav-contents .nav-links .nav-item a {
    color: #1f1b3b;
    font-size: 8vw;
    -webkit-transition: .3s;
    transition: .3s;
    padding: 2rem;
  }
  .nav-contents .nav-links .nav-item:hover a {
    background-color: #1f1b3b;
  }
  .nav-toggle {
    color: #dedde7;
    visibility: visible;
    border: 0px;
    background-color: inherit;
    cursor: pointer;
    -webkit-transition: 0.8s;
    transition: 0.8s;
    position: absolute;
    top: 70px;
    left: 50px;
  }
  .nav-toggle i {
    font-size: 6rem;
  }
  .cta-btn {
    display: none;
  }
  .nav-mobile-language-select {
    display: block;
  }
}

@media (min-width: 769px) {
  .mobile-logo {
    visibility: hidden;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
    -webkit-transform: rotateX(-90deg);
            transform: rotateX(-90deg);
  }
  50% {
    -webkit-transform: rotateX(-20deg);
            transform: rotateX(-20deg);
  }
  100% {
    opacity: 1;
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    -webkit-transform: rotateX(-90deg);
            transform: rotateX(-90deg);
  }
  50% {
    -webkit-transform: rotateX(-20deg);
            transform: rotateX(-20deg);
  }
  100% {
    opacity: 1;
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg);
  }
}

.pattern {
  position: absolute;
}

.pattern-1 {
  width: 6vw;
  left: 14vw;
  top: 36vh;
}

.pattern-1 img {
  opacity: .1;
  -webkit-filter: invert(40%) sepia(50%) saturate(4000%) hue-rotate(230deg) brightness(100%) contrast(100%);
          filter: invert(40%) sepia(50%) saturate(4000%) hue-rotate(230deg) brightness(100%) contrast(100%);
}

.pattern-2 {
  width: 4vw;
  left: 7vw;
  top: 30vh;
}

.pattern-2 img {
  opacity: .2;
  -webkit-filter: invert(40%) sepia(50%) saturate(4000%) hue-rotate(230deg) brightness(100%) contrast(100%);
          filter: invert(40%) sepia(50%) saturate(4000%) hue-rotate(230deg) brightness(100%) contrast(100%);
}

.pattern-3 {
  width: 6vw;
  right: 7vw;
  top: 44vh;
}

.pattern-3 img {
  opacity: .1;
  -webkit-filter: invert(40%) sepia(50%) saturate(4000%) hue-rotate(230deg) brightness(100%) contrast(100%);
          filter: invert(40%) sepia(50%) saturate(4000%) hue-rotate(230deg) brightness(100%) contrast(100%);
}

.pattern-4 {
  width: 8vw;
  right: 14vw;
  top: 40vh;
}

.pattern-4 img {
  opacity: .1;
  -webkit-filter: invert(40%) sepia(50%) saturate(4000%) hue-rotate(130deg) brightness(100%) contrast(100%);
          filter: invert(40%) sepia(50%) saturate(4000%) hue-rotate(130deg) brightness(100%) contrast(100%);
}

.pattern-5 {
  width: 7vw;
  left: 10vw;
  top: 10vh;
}

.pattern-5 img {
  opacity: .2;
  -webkit-filter: invert(40%) sepia(50%) saturate(4000%) hue-rotate(130deg) brightness(100%) contrast(100%);
          filter: invert(40%) sepia(50%) saturate(4000%) hue-rotate(130deg) brightness(100%) contrast(100%);
}

.pattern-6 {
  width: 8vw;
  right: 10vw;
  top: 30rem;
}

.pattern-6 img {
  opacity: .1;
  -webkit-filter: invert(40%) sepia(50%) saturate(4000%) hue-rotate(230deg) brightness(100%) contrast(100%);
          filter: invert(40%) sepia(50%) saturate(4000%) hue-rotate(230deg) brightness(100%) contrast(100%);
}

.pattern-7 {
  width: 6vw;
  right: 10vw;
  top: 30%;
}

.pattern-7 img {
  opacity: .1;
  -webkit-filter: invert(40%) sepia(50%) saturate(4000%) hue-rotate(230deg) brightness(100%) contrast(100%);
          filter: invert(40%) sepia(50%) saturate(4000%) hue-rotate(230deg) brightness(100%) contrast(100%);
}

.pattern-8 {
  width: 16vw;
  left: 6vw;
  top: 20vh;
}

.pattern-8 img {
  opacity: .1;
  -webkit-filter: invert(40%) sepia(50%) saturate(4000%) hue-rotate(230deg) brightness(100%) contrast(100%);
          filter: invert(40%) sepia(50%) saturate(4000%) hue-rotate(230deg) brightness(100%) contrast(100%);
}

.pattern-9 {
  width: 12vw;
  right: 20vw;
  top: 26vh;
}

.pattern-9 img {
  opacity: .3;
  -webkit-filter: invert(40%) sepia(50%) saturate(4000%) hue-rotate(230deg) brightness(100%) contrast(100%);
          filter: invert(40%) sepia(50%) saturate(4000%) hue-rotate(230deg) brightness(100%) contrast(100%);
}

.pattern-10 {
  width: 12vw;
  right: 6vw;
  top: 10vh;
}

.pattern-10 img {
  opacity: .1;
  -webkit-filter: invert(40%) sepia(50%) saturate(4000%) hue-rotate(230deg) brightness(100%) contrast(100%);
          filter: invert(40%) sepia(50%) saturate(4000%) hue-rotate(230deg) brightness(100%) contrast(100%);
}

.cookie-consent-container {
  display: none;
  position: fixed;
  z-index: 1;
  bottom: 0;
  padding: 8px;
  font-size: 1.4rem;
  margin: 0 auto;
  width: 180px;
  right: 20px;
  bottom: 20px;
  background: rgba(34, 1, 61, 0.8);
  border: 2px solid #7B61FF;
  border-radius: 8px;
}

.cookie-consent-container .cookie-consent a {
  background-color: #050e2d;
  color: white;
  font-weight: bold;
  border: 1px solid #7B61FF;
  border-radius: 4px;
  cursor: pointer;
  text-decoration: none;
  padding: 5px;
}

.cookie-consent-container .cookie-consent .cookie-consent-buttons {
  margin: 10px 0;
}

.cookie-consent-container .cookie-consent .cookie-consent-buttons .cookie-consent-buttons--details {
  background-color: #ff000000;
  border: 1px solid #6006ce;
  font-weight: normal;
}

.section-hero {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  width: 100vw;
  height: 100vh;
  background-image: url("/src/img/hero-bkg1.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  will-change: transform, opacity;
}

.video-wrapper {
  position: absolute;
  top: 0;
  left: 0;
}

.video-wrapper video {
  height: 100vh;
  width: 100vw;
  -o-object-fit: cover;
     object-fit: cover;
  -webkit-transform: rotateY(180deg);
          transform: rotateY(180deg);
  opacity: .5;
}

.hero-mask {
  -webkit-mask-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.7)), to(transparent));
  -webkit-mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.7), transparent);
          mask-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.7)), to(transparent));
          mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.7), transparent);
}

.hero-section-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 100vw;
  height: 60vh;
  z-index: 2;
}

#typewriter {
  font-size: 3rem;
}

#typewriter span {
  font-weight: 200;
}

.hero-copy {
  text-shadow: 0px 10px 30px #00000030;
}

.hero-copy h1 {
  font-size: 110px !important;
}

.hero-asset {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.hero-asset img {
  height: 26vw;
}

.arrow-down {
  position: absolute;
  margin: 80vh auto 0;
  width: 20rem;
  height: 10rem;
  -webkit-filter: drop-shadow(0px 0px 7px rgba(0, 0, 0, 0.2));
          filter: drop-shadow(0px 0px 7px rgba(0, 0, 0, 0.2));
  z-index: 2;
}

.arrow-down img {
  margin: 3rem auto;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  padding: 2rem 3rem;
}

.arrow-down img:hover {
  margin-top: 4rem;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

@media (max-width: 1000px) {
  .hero-copy h1 {
    font-size: 100px !important;
  }
  .hero-asset {
    display: none;
  }
}

@media (max-width: 768px) {
  .hero-section-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
        -ms-flex-direction: column-reverse;
            flex-direction: column-reverse;
  }
  .hero-copy h1 {
    font-size: 80px !important;
  }
}

.section-services {
  background: #dedde7;
  will-change: transform, opacity;
}

.grid-parent {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (3fr)[3];
      grid-template-columns: repeat(3, 3fr);
  -ms-grid-rows: (3fr)[3];
      grid-template-rows: repeat(3, 3fr);
  grid-column-gap: 2rem;
  grid-row-gap: 2rem;
  padding: 0 0 2rem 0;
  max-width: 768px;
}

.div1 {
  -ms-grid-row: 1;
  -ms-grid-row-span: 2;
  -ms-grid-column: 1;
  -ms-grid-column-span: 1;
  grid-area: 1 / 1 / 3 / 2;
}

.div2 {
  -ms-grid-row: 3;
  -ms-grid-row-span: 1;
  -ms-grid-column: 1;
  -ms-grid-column-span: 1;
  grid-area: 3 / 1 / 4 / 2;
}

.div3 {
  -ms-grid-row: 1;
  -ms-grid-row-span: 1;
  -ms-grid-column: 2;
  -ms-grid-column-span: 1;
  grid-area: 1 / 2 / 2 / 3;
}

.div4 {
  -ms-grid-row: 2;
  -ms-grid-row-span: 2;
  -ms-grid-column: 2;
  -ms-grid-column-span: 1;
  grid-area: 2 / 2 / 4 / 3;
}

.div5 {
  -ms-grid-row: 1;
  -ms-grid-row-span: 1;
  -ms-grid-column: 3;
  -ms-grid-column-span: 1;
  grid-area: 1 / 3 / 2 / 4;
}

.div6 {
  -ms-grid-row: 2;
  -ms-grid-row-span: 1;
  -ms-grid-column: 3;
  -ms-grid-column-span: 1;
  grid-area: 2 / 3 / 3 / 4;
}

.div7 {
  -ms-grid-row: 3;
  -ms-grid-row-span: 1;
  -ms-grid-column: 3;
  -ms-grid-column-span: 1;
  grid-area: 3 / 3 / 4 / 4;
}

.tile {
  background-color: #1EF1BF;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  background-size: cover;
  opacity: .8;
  -webkit-transition: all .2s ease-in-out;
  transition: all .2s ease-in-out;
  cursor: default;
  border-radius: 8px;
  height: auto;
}

.tile h2 {
  height: 20%;
  color: #dedde7;
  font-size: 1.9rem;
  font-weight: bold;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-family: "Readex Pro", Times, serif;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin: 0 0 2rem 0;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
}

.tile p {
  height: 80%;
  font-size: 1.6rem;
  font-weight: bold;
  padding: 1.4rem;
  text-align: center;
  opacity: .85;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
  text-shadow: 0px 0px 6px #1f1b3b;
}

.tile:hover {
  -webkit-transform: scale(1.02);
          transform: scale(1.02);
  opacity: 1;
}

.tile:hover h2 {
  color: #dedde7;
}

.tile:hover p {
  color: #dedde7;
  opacity: 1;
}

.square-tile {
  min-height: 220px;
}

.div1 {
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(11, 11, 180, 0.8)), to(#050e2d)), url("../img/tile_1.jpeg");
  background-image: linear-gradient(to bottom, rgba(11, 11, 180, 0.8), #050e2d), url("../img/tile_1.jpeg");
}

.div2 {
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(11, 11, 180, 0.8)), to(#050e2d)), url("../img/tile_2.jpeg");
  background-image: linear-gradient(to bottom, rgba(11, 11, 180, 0.8), #050e2d), url("../img/tile_2.jpeg");
}

.div3 {
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(11, 11, 180, 0.8)), to(#050e2d)), url("../img/tile_3.jpeg");
  background-image: linear-gradient(to bottom, rgba(11, 11, 180, 0.8), #050e2d), url("../img/tile_3.jpeg");
}

.div4 {
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(11, 11, 180, 0.8)), to(#050e2d)), url("../img/tile_4.jpeg");
  background-image: linear-gradient(to bottom, rgba(11, 11, 180, 0.8), #050e2d), url("../img/tile_4.jpeg");
}

.div5 {
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(11, 11, 180, 0.8)), to(#050e2d)), url("../img/tile_5.jpeg");
  background-image: linear-gradient(to bottom, rgba(11, 11, 180, 0.8), #050e2d), url("../img/tile_5.jpeg");
}

.div6 {
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(11, 11, 180, 0.8)), to(#050e2d)), url("../img/tile_6.jpeg");
  background-image: linear-gradient(to bottom, rgba(11, 11, 180, 0.8), #050e2d), url("../img/tile_6.jpeg");
}

.div7 {
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(11, 11, 180, 0.8)), to(#050e2d)), url("../img/tile_7.jpeg");
  background-image: linear-gradient(to bottom, rgba(11, 11, 180, 0.8), #050e2d), url("../img/tile_7.jpeg");
}

@media (max-width: 1200px) {
  .tile p {
    opacity: .9;
    text-shadow: 0 0px 4px rgba(0, 0, 0, 0.5);
  }
}

@media (max-width: 1000px) {
  .background-pattern {
    display: none;
  }
  .grid-parent {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: (2fr)[2];
        grid-template-columns: repeat(2, 2fr);
    -ms-grid-rows: (1fr)[5];
        grid-template-rows: repeat(5, 1fr);
    grid-column-gap: 2rem;
    grid-row-gap: 2rem;
  }
  .div1 {
    -ms-grid-row: 1;
    -ms-grid-row-span: 2;
    -ms-grid-column: 1;
    -ms-grid-column-span: 1;
    grid-area: 1 / 1 / 3 / 2;
  }
  .div2 {
    -ms-grid-row: 3;
    -ms-grid-row-span: 1;
    -ms-grid-column: 1;
    -ms-grid-column-span: 1;
    grid-area: 3 / 1 / 4 / 2;
  }
  .div3 {
    -ms-grid-row: 1;
    -ms-grid-row-span: 1;
    -ms-grid-column: 2;
    -ms-grid-column-span: 1;
    grid-area: 1 / 2 / 2 / 3;
  }
  .div4 {
    -ms-grid-row: 2;
    -ms-grid-row-span: 2;
    -ms-grid-column: 2;
    -ms-grid-column-span: 1;
    grid-area: 2 / 2 / 4 / 3;
  }
  .div5 {
    -ms-grid-row: 4;
    -ms-grid-row-span: 1;
    -ms-grid-column: 1;
    -ms-grid-column-span: 1;
    grid-area: 4 / 1 / 5 / 2;
  }
  .div6 {
    -ms-grid-row: 4;
    -ms-grid-row-span: 1;
    -ms-grid-column: 2;
    -ms-grid-column-span: 1;
    grid-area: 4 / 2 / 5 / 3;
  }
  .div7 {
    -ms-grid-row: 5;
    -ms-grid-row-span: 1;
    -ms-grid-column: 1;
    -ms-grid-column-span: 1;
    grid-area: 5 / 1 / 6 / 2;
  }
  .square-tile {
    height: 210px;
    border-radius: 8px;
  }
  .vertical-tile {
    height: 440px;
    border-radius: 8px;
  }
}

@media (max-width: 768px) {
  .tiles-container {
    padding: 0rem;
  }
  .grid-parent {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  .tile {
    opacity: .9;
  }
  .tile h2 {
    font-size: 2.6rem;
    color: #1EF1BF;
    font-family: "Readex Pro", Times, serif;
    font-weight: 100;
  }
  .tile p {
    font-size: 2.2rem;
    width: 20ch;
    margin: 0 auto;
    color: #dedde7;
  }
  .tile h2, .tile p {
    text-shadow: 2px 2px 32px #1f1b3b;
  }
  .square-tile, .vertical-tile {
    width: 82vw;
    height: 300px;
  }
}

.section-why-go-online {
  background: url("../img/bkg_why_online.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  will-change: transform;
  text-shadow: 0px 0px 28px #1f1b3b;
}

.section-why-go-online h2 {
  font-size: 2.4rem;
  letter-spacing: -.1rem;
}

.section-why-go-online li {
  font-size: 1.8rem;
  list-style-type: none;
}

.section-why-go-online strong {
  color: #1EF1BF;
  font-weight: normal;
}

.section-why-go-online .background-glass {
  border: 3px solid rgba(31, 27, 59, 0.1);
  padding: 4rem;
}

.section-why-go-online .background-glass .fa-caret-right {
  margin-right: 1rem;
}

@media (max-width: 768px) {
  .section-why-go-online {
    padding: 0;
  }
  .section-why-go-online .background-glass {
    max-width: 90vw;
    padding: 2rem;
  }
  .section-why-go-online .background-glass h2, .section-why-go-online .background-glass li {
    max-width: 60vw;
    padding-bottom: 2rem;
  }
}

.section-workflow {
  position: relative;
  background: #dedde7;
  height: 70rem;
}

.workflow-tiles {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  gap: 2rem;
}

.workflow-tile {
  width: 280px;
  height: 280px;
  border-radius: 8px;
  opacity: .8;
  -webkit-transition: all .2s ease-in-out;
  transition: all .2s ease-in-out;
  padding: 3rem;
}

.workflow-tile .workflow-tile-top {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  height: 50%;
  font-size: 8rem;
  font-family: "Nunito", serif;
  font-weight: 100;
}

.workflow-tile .workflow-tile-bottom {
  height: 50%;
}

.workflow-tile .workflow-tile-bottom h1 {
  font-family: "Readex Pro", Times, serif;
  font-weight: 400;
  font-size: 2.6rem;
  margin-top: 1rem;
}

.workflow-tile .workflow-tile-bottom p {
  height: 50%;
  width: 100%;
  font-size: 1.6rem;
  line-height: 110%;
  text-align: left;
  margin: 1rem 0 0 0;
}

.workflow-tile .workflow-tile-icon {
  width: 5.6rem;
  height: 5.6rem;
  margin: 3rem 0 0 .8rem;
}

.workflow-tile .workflow-tile-icon img {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.workflow-tile:hover {
  -webkit-transform: scale(1.02);
          transform: scale(1.02);
  opacity: 1;
}

.workflow-tile:hover h2 {
  color: #7B61FF;
}

.workflow-tile:hover p {
  color: #dedde7;
}

.workflow-tile-1 {
  background-image: -webkit-gradient(linear, left bottom, left top, from(rgba(11, 11, 180, 0.8)), to(#050e2d)), url("../img/tile_6.jpeg");
  background-image: linear-gradient(to top, rgba(11, 11, 180, 0.8), #050e2d), url("../img/tile_6.jpeg");
  background-size: 250%;
}

.workflow-tile-2 {
  background-image: -webkit-gradient(linear, left bottom, left top, from(rgba(11, 11, 180, 0.8)), to(#050e2d)), url("../img/tile_5.jpeg");
  background-image: linear-gradient(to top, rgba(11, 11, 180, 0.8), #050e2d), url("../img/tile_5.jpeg");
}

.workflow-tile-3 {
  background-image: -webkit-gradient(linear, left bottom, left top, from(rgba(11, 11, 180, 0.8)), to(#050e2d)), url("../img/tile_1.jpeg");
  background-image: linear-gradient(to top, rgba(11, 11, 180, 0.8), #050e2d), url("../img/tile_1.jpeg");
  background-size: 220%;
}

@media (max-width: 1000px) {
  .section-workflow {
    position: relative;
    background: #dedde7;
    height: 130rem;
  }
  .workflow-tiles {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    gap: 2rem;
    margin: 0 auto;
  }
  .workflow-tile {
    width: 600px;
    height: 280px;
  }
}

@media (max-width: 768px) {
  .workflow-tile {
    width: 80vw;
    height: 280px;
  }
}

.section-portfolio {
  background: #1f1b3b;
  height: calc($slider-height+100px);
  will-change: transform;
}

.portfolio-container {
  margin: 0 auto;
  width: 50vw;
  height: auto;
}

.slider {
  position: relative;
  top: 300px;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: 360px;
  height: 680px;
}

.slider a {
  text-decoration: none;
}

.wrapper {
  overflow: hidden;
  position: relative;
  width: 360px;
  height: 680px;
}

.slides {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  top: 0;
  left: -360px;
  width: 10000px;
}

.slides.shifting {
  -webkit-transition: left .2s ease-out;
  transition: left .2s ease-out;
}

.slide {
  width: 360px;
  height: 680px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-transition: all .7s;
  transition: all .7s;
  position: relative;
  opacity: .8;
}

.slide h2 {
  position: relative;
  bottom: 12rem;
  color: #1f1b3b;
  font-size: 2.1rem;
  text-align: center;
  -webkit-transition: all .7s;
  transition: all .7s;
  padding: 0 30px 0;
  text-shadow: 0px 1px 8px rgba(255, 255, 255, 0.2);
}

.slide:hover {
  opacity: 1;
}

.slide:hover h2 {
  color: #7B61FF;
}

.slider.loaded .slide:nth-child(2),
.slider.loaded .slide:nth-child(7),
.slider.loaded .slide:nth-child(1),
.slider.loaded .slide:nth-child(6),
.slider.loaded .slide:nth-child(3),
.slider.loaded .slide:nth-child(4),
.slider.loaded .slide:nth-child(5) {
  background: #ffffff00;
}

.control {
  position: absolute;
  top: 50%;
  width: 140px;
  height: 140px;
  z-index: 2;
}

.prev,
.next {
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.prev {
  background-image: url("../img/arrow.svg");
  background-size: contain;
  left: -130px;
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}

.next {
  background-image: url("../img/arrow.svg");
  background-size: contain;
  right: -130px;
  -webkit-transform: rotate(270deg);
          transform: rotate(270deg);
}

.prev:hover {
  left: -140px;
}

.next:hover {
  right: -140px;
}

@media (max-width: 1200px) {
  .portfolio-container {
    width: 70vw;
  }
}

@media (max-width: 1000px) {
  .portfolio-container {
    width: 80vw;
  }
}

@media (max-width: 500px) {
  .portfolio-container {
    width: 100vw;
  }
  .slide h2 {
    font-size: 3rem;
  }
  .prev {
    left: -10px;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
  }
  .next {
    right: -10px;
    -webkit-transform: rotate(270deg);
            transform: rotate(270deg);
  }
  .prev:hover {
    left: -16px;
  }
  .next:hover {
    right: -16px;
  }
}

.section-call-to-action {
  background: #dedde7;
  height: auto;
}

.section-contact-form {
  background: url("../img/bkg_contact-form.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 0rem 0 12rem 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  height: auto;
}

.section-contact-form .send-btn {
  color: #1f1b3b;
  background-color: #1EF1BF;
  font-size: 2rem;
  font-weight: bold;
  border: none;
  border-radius: 8px;
  padding: 1rem 3rem;
  margin: 0 0 1rem 1rem;
  cursor: pointer;
}

input, select, textarea {
  all: unset;
  color: #dedde7;
  border-left: 4px solid #1EF1BF;
  padding: 1rem 2rem 1rem;
  margin: 1rem 0;
  font-size: 1.4rem;
  border-radius: 4px;
}

input[type=text]:focus, input[type=email]:focus, textarea:focus {
  background: #101078;
  -webkit-transition: .3s ease-in-out;
  transition: .3s ease-in-out;
  outline: 1px solid #64a0aa;
}

textarea {
  min-height: 12rem;
}

::-webkit-input-placeholder {
  color: #64c8a0;
  font-size: 1.3em;
}

:-ms-input-placeholder {
  color: #64c8a0;
  font-size: 1.3em;
}

::-ms-input-placeholder {
  color: #64c8a0;
  font-size: 1.3em;
}

::placeholder {
  color: #64c8a0;
  font-size: 1.3em;
}

.contact-methods {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (1fr)[2];
      grid-template-columns: repeat(2, 1fr);
  -ms-grid-rows: (1fr)[2];
      grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 2rem;
  grid-row-gap: 2rem;
}

.form-control {
  height: 3rem;
  width: 80%;
  background: #000514;
  margin-left: 1rem;
}

.contact1 {
  -ms-grid-row: 1;
  -ms-grid-row-span: 2;
  -ms-grid-column: 1;
  -ms-grid-column-span: 1;
  grid-area: 1 / 1 / 3 / 2;
}

.contact2 {
  -ms-grid-row: 1;
  -ms-grid-row-span: 1;
  -ms-grid-column: 2;
  -ms-grid-column-span: 1;
  grid-area: 1 / 2 / 2 / 3;
}

.contact3 {
  -ms-grid-row: 2;
  -ms-grid-row-span: 1;
  -ms-grid-column: 2;
  -ms-grid-column-span: 1;
  grid-area: 2 / 2 / 3 / 3;
}

.contact2, .contact3 {
  padding: 3rem;
}

.contact1, .contact2, .contact3 {
  border: 2px solid rgba(200, 50, 255, 0.1);
}

h2 {
  color: #1EF1BF;
  font-size: 2.2rem;
}

h2 a {
  color: #1EF1BF;
}

.contact2 p, .contact3 p {
  font-family: "Readex Pro", Times, serif;
  font-size: 2.8rem;
}

.contact2 a, .contact3 a {
  color: #dedde7;
}

.contact2 img, .contact3 img {
  width: 260px;
}

@media (max-width: 1000px) {
  .contact-methods {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
}

@media (max-width: 768px) {
  .section-contact-form {
    padding: 0 0 6rem 0;
  }
  .contact-methods {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  .contact2 h2, .contact3 h2 {
    font-size: 2rem;
  }
  .contact2 p, .contact2 a, .contact3 p, .contact3 a {
    font-size: 2.4rem;
  }
}

.section-footer {
  background: #dedde7;
  padding: 4rem 0 1rem 0;
  height: auto;
}

.section-footer .footer-logo {
  color: #1f1b3b;
  font-family: 'Iceberg', cursive;
  font-size: 2.1rem;
}

.section-footer h1 {
  color: #1f1b3b;
  font-size: 2.2rem;
  padding: 0rem 0 .8rem 0;
}

.section-footer li {
  list-style-type: none;
}

.section-footer li a {
  color: #7B61FF;
  text-decoration: none;
  font-size: 1.8rem;
}

.section-footer p {
  color: #7B61FF;
  font-size: 1.8rem;
}

.section-footer p a {
  color: #7B61FF;
  text-decoration: none;
}

.footer-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.footer-social {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
}

.footer-social img {
  width: 4rem;
  height: 4rem;
  margin: 2rem 1rem 0 0;
}

.copyright {
  color: #1f1b3b;
  text-align: center;
  font-size: 1.4rem;
  font-weight: bold;
  padding: 2rem 0 0 0;
  opacity: .5;
}

@media (max-width: 1000px) {
  .footer-container {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: (1fr)[2];
        grid-template-columns: repeat(2, 1fr);
  }
  .footer-col-1 {
    -ms-grid-row: 1;
    -ms-grid-row-span: 1;
    -ms-grid-column: 1;
    -ms-grid-column-span: 1;
    grid-area: 1 / 1 / 2 / 2;
    padding: 0 0 4rem 0;
  }
  .footer-col-2 {
    -ms-grid-row: 2;
    -ms-grid-row-span: 1;
    -ms-grid-column: 1;
    -ms-grid-column-span: 1;
    grid-area: 2 / 1 / 3 / 2;
  }
  .footer-col-3 {
    -ms-grid-row: 2;
    -ms-grid-row-span: 1;
    -ms-grid-column: 2;
    -ms-grid-column-span: 1;
    grid-area: 2 / 2 / 3 / 3;
  }
}

@media (max-width: 768px) {
  .footer-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  .footer-column {
    padding: 0 0 4rem 0;
  }
}

.contact-thank-you {
  padding: 16vh 0;
}

.privacy-policy {
  padding-top: 10vh;
}

.privacy-policy h3 {
  font-size: 2rem;
  margin: 3rem 0 1rem 0;
}

.privacy-policy p {
  font-size: 1.8rem;
  line-height: 120%;
}

.privacy-policy input {
  outline: 1px solid #1EF1BF;
  cursor: pointer;
  margin: 5rem 0;
}

.case-studies {
  padding: 20rem 0 0 0;
}

.case-study-container {
  height: auto;
  width: 80vw;
  margin: 0 auto;
  -webkit-box-shadow: 0px 10px 64px 0px rgba(0, 0, 0, 0.1);
          box-shadow: 0px 10px 64px 0px rgba(0, 0, 0, 0.1);
  border-radius: 1.2rem;
}

.case-study-header {
  height: 50rem;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 1.2rem;
}

.case-study-header-nav {
  position: relative;
}

.header-nav-arrows {
  position: absolute;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  gap: 60vw;
  width: 90%;
  left: 50%;
  top: 36rem;
  -webkit-transform: translate(-50%, 0);
          transform: translate(-50%, 0);
}

.header-nav-arrows .portfolio-nav-prev {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}

.header-nav-arrows .portfolio-nav-prev img {
  height: 4rem;
}

.header-nav-arrows .portfolio-nav-next {
  -webkit-transform: rotate(270deg);
          transform: rotate(270deg);
}

.header-nav-arrows .portfolio-nav-next img {
  height: 4rem;
}

.case-study-title {
  margin-left: 50%;
}

.case-study-title h1 {
  text-shadow: 0 1px 18px rgba(24, 0, 80, 0.5);
}

.case-study-description {
  width: 40vw;
  padding-right: 8rem;
  text-align: justify;
}

.case-study-description .see-live-btn a {
  font-family: "Nunito", serif;
  font-weight: bold;
  font-size: 2rem;
  color: #1EF1BF;
  -webkit-transition: .3s all;
  transition: .3s all;
  text-decoration: none;
  background-color: #1f1b3b;
  padding: 1rem;
  border-radius: 1rem;
}

.case-study-description .see-live-btn a:hover {
  color: #7B61FF;
}

.case-study-description .see-live-btn a:visited {
  color: #dedde7;
}

.project-scope-section {
  max-height: 80rem;
}

.project-visualization-pc {
  display: visible !important;
}

.project-visualization-mobile {
  display: none !important;
}

.tech-section {
  height: auto;
}

.design-section {
  height: auto;
}

.bright-section {
  background: #dedde7;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  padding: 4rem 8rem;
}

.bright-section .case-study-design img {
  width: 40vw;
  max-width: unset;
  margin: 0 auto;
  top: 4rem;
  right: 4rem;
  border-radius: 2rem;
  -webkit-box-shadow: 8px 8px 24px 0px rgba(40, 40, 60, 0.3);
          box-shadow: 8px 8px 24px 0px rgba(40, 40, 60, 0.3);
}

.bright-section .case-study-description p {
  color: #1f1b3b;
  font-size: 2rem;
  line-height: 126%;
}

.dark-section {
  background: #383250;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  padding: 4rem 8rem;
}

.dark-section .case-study-description p {
  color: #dedde7;
  font-size: 2.2rem;
}

.dark-section .case-study-description-title h1 {
  font-size: 5rem;
}

.dark-section .tech-stack-logos {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  padding-right: 8rem;
}

.dark-section .tech-stack-logos .tech-stack-logos-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
}

.dark-section .tech-stack-logos img {
  width: 5vw;
  height: 5vw;
  -webkit-filter: contrast(200%) grayscale(90%);
          filter: contrast(200%) grayscale(90%);
  -webkit-transition: ease-in-out .3s;
  transition: ease-in-out .3s;
  margin: 1rem;
}

.dark-section .tech-stack-logos img:hover {
  -webkit-filter: unset;
          filter: unset;
}

.dark-section .tech-stack-logos a {
  background-color: #331a5d;
  margin-bottom: 1rem;
  border-radius: 1rem;
}

.dark-section .tech-stack-logos a:hover {
  color: #7B61FF;
  background-color: inherit;
}

.dark-section .tech-stack-logos a:visited {
  color: #dedde7;
}

.color-palette-wrapper {
  width: 26vw;
  height: 20rem;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (fr)[1];
      grid-template-columns: repeat(1, fr);
  -ms-grid-rows: (1fr)[2];
      grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 8px;
  grid-row-gap: 8px;
}

.color-palette-wrapper .color-palette {
  border-radius: 8px;
  -webkit-box-shadow: 8px 8px 24px 0px rgba(40, 40, 60, 0.08);
          box-shadow: 8px 8px 24px 0px rgba(40, 40, 60, 0.08);
}

.color-palette-wrapper .color-palette-1 {
  -ms-grid-row: 1;
  -ms-grid-row-span: 1;
  -ms-grid-column: 1;
  -ms-grid-column-span: 1;
  grid-area: 1 / 1 / 2 / 2;
  background-color: #6ca95e;
}

.color-palette-wrapper .color-palette-2 {
  -ms-grid-row: 1;
  -ms-grid-row-span: 1;
  -ms-grid-column: 2;
  -ms-grid-column-span: 1;
  grid-area: 1 / 2 / 2 / 3;
  background-color: #fcfffc;
}

.color-palette-wrapper .color-palette-3 {
  -ms-grid-row: 2;
  -ms-grid-row-span: 1;
  -ms-grid-column: 1;
  -ms-grid-column-span: 1;
  grid-area: 2 / 1 / 3 / 2;
  background-color: #1d3218;
}

.color-palette-wrapper .color-palette-4 {
  -ms-grid-row: 2;
  -ms-grid-row-span: 1;
  -ms-grid-column: 2;
  -ms-grid-column-span: 1;
  grid-area: 2 / 2 / 3 / 3;
  background-color: #ebf840;
}

.color-palette-wrapper p {
  padding: 5rem 0 0 2rem;
  text-transform: uppercase;
  opacity: .6;
}

.color-palette-wrapper .color-palette-3 p {
  color: #dedde7;
}

.typography-wrapper {
  padding: 6rem 0;
  width: 18vw;
}

.typography-wrapper img {
  padding: 2rem 0;
  width: auto;
  opacity: .9;
}

.mobile-section {
  display: none;
  height: auto;
  background-image: url("../img/portfolio/background_mobile_view.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.mobile-view img {
  margin: 0 auto;
  height: 70vh;
}

.mobile-background-decal p {
  font-family: 'Iceberg', cursive;
  font-size: 12vw !important;
  line-height: 70%;
  color: rgba(0, 0, 0, 0.07) !important;
  text-shadow: 0px 0px 20px rgba(40, 40, 70, 0.3);
}

.portfolio-navigation {
  width: inherit;
  height: 20rem;
  height: 0px;
  padding: 0;
}

.portfolio-nav-arrows {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  gap: 20vw;
  left: 50%;
  bottom: 18rem;
  -webkit-transform: translate(-50%, 0);
          transform: translate(-50%, 0);
}

.portfolio-nav-arrows .portfolio-nav-prev {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}

.portfolio-nav-arrows .portfolio-nav-prev img {
  height: 4rem;
}

.portfolio-nav-arrows .portfolio-nav-next {
  -webkit-transform: rotate(270deg);
          transform: rotate(270deg);
}

.portfolio-nav-arrows .portfolio-nav-next img {
  height: 4rem;
}

@media (max-width: 1400px) and (min-width: 1000px) {
  .project-scope-section {
    min-height: 100rem;
  }
}

@media (min-width: 1400px) {
  .portfolio-filler {
    height: 0rem;
  }
}

@media (min-width: 1600px) {
  .case-study-header {
    height: 60rem;
  }
  .portfolio-filler {
    height: 20rem;
  }
}

@media (min-width: 1800px) {
  .portfolio-filler {
    height: 44rem;
  }
}

@media (min-width: 2000px) {
  .portfolio-filler {
    height: 130rem;
  }
  .mobile-background-decal {
    margin-top: 30vh;
  }
  .bright-section .case-study-design img {
    height: 3474px;
    width: 1000px;
  }
  .dark-section .tech-stack-logos img {
    width: 10rem;
    height: 10rem;
    -webkit-filter: contrast(200%) grayscale(90%);
            filter: contrast(200%) grayscale(90%);
    -webkit-transition: ease-in-out .3s;
    transition: ease-in-out .3s;
    margin: 1rem;
  }
}

@media (max-width: 1800px) {
  .mobile-background-decal {
    display: none;
  }
}

@media (max-width: 1200px) {
  .case-study-title {
    margin-left: 10%;
  }
  .portfolio-navigation {
    width: inherit;
    height: 10rem;
    padding: 0;
  }
  .portfolio-nav-arrows {
    position: absolute;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    gap: 20vw;
    left: 50%;
    bottom: 7rem;
    -webkit-transform: translate(-50%, 0);
            transform: translate(-50%, 0);
  }
}

@media (max-width: 1000px) {
  .case-study-description {
    width: 70vw;
    padding-right: 1rem;
  }
  .design-section .case-study-description {
    width: 100%;
  }
  .color-palette-wrapper {
    width: 70vw;
  }
  .case-study-container {
    width: 95vw;
  }
  .project-visualization-pc {
    display: none !important;
  }
  .project-visualization-mobile {
    display: inline !important;
  }
  .project-visualization-mobile .case-study-design img {
    border-radius: 8px;
    margin: 8rem auto;
    width: 90%;
    -webkit-box-shadow: 0px 10px 64px 0px rgba(104, 59, 239, 0.847);
            box-shadow: 0px 10px 64px 0px rgba(104, 59, 239, 0.847);
  }
  .dark-section .tech-stack-logos img {
    width: 6rem;
    height: 6rem;
    -webkit-filter: contrast(200%) grayscale(90%);
            filter: contrast(200%) grayscale(90%);
    -webkit-transition: ease-in-out .3s;
    transition: ease-in-out .3s;
    margin: 1rem;
  }
  .typography-wrapper {
    width: 40vw;
  }
  .mobile-view {
    position: absolute;
  }
  .mobile-view img {
    width: 120%;
  }
}

@media (max-width: 768px) {
  .header-nav-arrows {
    gap: 40vw;
    margin: 1rem;
  }
  .bright-section {
    padding-left: 4rem;
  }
  .bright-section .case-study-description-title h1 {
    font-size: 9vw;
  }
  .dark-section {
    padding-left: 4rem;
  }
  .dark-section .case-study-description-title h1 {
    font-size: 9vw;
  }
  .leading-text {
    max-width: 80vw;
    padding-top: 14rem;
  }
  .case-study-header {
    background-position: 25%;
    background-size: cover;
  }
}

html.has-scroll-smooth {
  overflow: hidden;
}

html.has-scroll-dragging {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.has-scroll-smooth body {
  overflow: hidden;
}

.has-scroll-smooth [data-scroll-container] {
  min-height: 100vh;
}

[data-scroll-direction="horizontal"] [data-scroll-container] {
  height: 100vh;
  display: inline-block;
  white-space: nowrap;
}

[data-scroll-direction="horizontal"] [data-scroll-section] {
  display: inline-block;
  vertical-align: top;
  white-space: nowrap;
  height: 100%;
}

.c-scrollbar {
  position: absolute;
  right: 0;
  top: 0;
  width: 11px;
  height: 100%;
  -webkit-transform-origin: center right;
          transform-origin: center right;
  -webkit-transition: opacity 0.3s, -webkit-transform 0.3s;
  transition: opacity 0.3s, -webkit-transform 0.3s;
  transition: transform 0.3s, opacity 0.3s;
  transition: transform 0.3s, opacity 0.3s, -webkit-transform 0.3s;
  opacity: 0;
}

.c-scrollbar:hover {
  -webkit-transform: scaleX(1.45);
          transform: scaleX(1.45);
}

.c-scrollbar:hover, .has-scroll-scrolling .c-scrollbar, .has-scroll-dragging .c-scrollbar {
  opacity: 1;
}

[data-scroll-direction="horizontal"] .c-scrollbar {
  width: 100%;
  height: 10px;
  top: auto;
  bottom: 0;
  -webkit-transform: scaleY(1);
          transform: scaleY(1);
}

[data-scroll-direction="horizontal"] .c-scrollbar:hover {
  -webkit-transform: scaleY(1.3);
          transform: scaleY(1.3);
}

.c-scrollbar_thumb {
  position: absolute;
  top: 0;
  right: 0;
  background-color: black;
  opacity: 0.5;
  width: 7px;
  border-radius: 10px;
  margin: 2px;
  cursor: -webkit-grab;
  cursor: grab;
}

.has-scroll-dragging .c-scrollbar_thumb {
  cursor: -webkit-grabbing;
  cursor: grabbing;
}

[data-scroll-direction="horizontal"] .c-scrollbar_thumb {
  right: auto;
  bottom: 0;
}

html {
  font-size: 62.5%;
}

body {
  background: #1f1b3b;
  color: #dedde7;
  font-family: "Nunito", serif;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

.section-container {
  margin: 0 auto;
  padding: 0 0 3rem 0;
  width: 50vw;
  max-width: 160rem;
}

.rounded-corners {
  border-radius: 8px;
}

.heading-font {
  font-family: "Readex Pro", Times, serif;
  font-size: 4.8rem;
  line-height: 90%;
  letter-spacing: -.16rem;
  max-width: 90vw;
  font-weight: normal;
}

.heading-font strong {
  color: #1EF1BF;
  font-weight: normal;
}

.heading-dark {
  color: #1f1b3b;
}

.heading-dark strong {
  color: #7B61FF;
  font-weight: normal;
}

.leading-text {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 8rem 0rem 6rem 0rem;
  max-width: 90ch;
}

.leading-text .accent {
  min-width: 1rem;
  border-radius: 8px;
  margin-right: 4rem;
}

.leading-text .accent-primary {
  background-color: #1EF1BF;
}

.leading-text .accent-secondary {
  background-color: #7B61FF;
}

.background-glass {
  background-color: rgba(123, 97, 255, 0.2);
  -webkit-backdrop-filter: blur(12px);
          backdrop-filter: blur(12px);
}

.custom-fade-in {
  opacity: 1;
  -webkit-transition: opacity 1s;
  transition: opacity 1s;
}

.custom-fade-out {
  opacity: 0;
  -webkit-transition: opacity 1s;
  transition: opacity 1s;
}

.scroll-class {
  opacity: 1;
}

@media (max-width: 1200px) {
  .section-container {
    padding: 0 0 4rem 2rem;
    width: 80vw;
  }
  .pattern {
    display: none;
  }
  .leading-text {
    max-width: 70vw;
  }
}

@media (max-width: 768px) {
  .section-container {
    padding: 0;
  }
  .heading-font {
    font-size: 4rem;
    line-height: 1;
  }
}
