.section-call-to-action {
    background: $offWhite;
    height: auto;

    h1 {
        // font-size: 5rem;
        // line-height: 1;
    }
}

@media (max-width: 1000px) {

}