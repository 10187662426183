.section-hero {
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: 100vh;
    background-image: url("/src/img/hero-bkg1.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    align-items: center;
    justify-content: center;
    will-change: transform, opacity;
}

.video-wrapper {
    // display: none;
    position: absolute;
    top: 0;
    left: 0;
    // opacity: .2;

    video {
        height: 100vh;
        width: 100vw;
        object-fit: cover;
        transform: rotateY(180deg);
        opacity: .5;
    }
  }

.hero-mask {
    mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.7), transparent);
}

.hero-section-container {
    display: flex;
    // flex-grow: shrink;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 60vh;
    // padding: 8rem;
    z-index: 2;
}

#typewriter {
    font-size: 3rem;
    // line-height: 10%;
    // border: 1px solid red;

    span {
        font-weight: 200;
    }
}

.hero-copy {
    text-shadow: 0px 10px 30px #00000030;
    // border: 1px solid gold;


    h1 {
        font-size: 110px !important;
        // border: 1px solid pink;

    }
}

.hero-asset {
    display: flex;
    flex-direction: column;

    img {
        height: 26vw;
        // filter: drop-shadow(0px 0px 3px rgba(140, 0, 255, 0.3));
    }
}

.arrow-down {
    position: absolute;
    margin: 80vh auto 0;
    width: 20rem;
    height: 10rem;
    filter: drop-shadow( 0px 0px 7px rgba(0, 0, 0, .2));
    z-index: 2;

    img {
        margin: 3rem auto;
        transition: 0.3s;
        padding: 2rem 3rem;
    }

    img:hover {
        margin-top: 4rem;
        transition: 0.3s;

    }
}

@media (max-width: 1000px) {

    .hero-copy {
        // margin-top: 200px;

        h1 {
            font-size: 100px !important;
        }
    }

    .hero-asset {
        display: none;
        // position: absolute;
        // margin: 0 0 300px 0;
        // opacity: 1;

        img {
            // height: 300px;
            // margin-top: 30px;
        }

    }
}

@media (max-width: 768px) {
    .hero-section-container {
        display: flex;
        flex-direction: column-reverse;
    }


    .hero-copy {
        h1 {
            font-size: 80px !important;
        }
    }

    .hero-asset {
        // margin: 0 0 16vh 0;
        // height: 54vw;
        // border: 1px solid red;

        img {
            // height: 280px;
            // margin-top: 100px;
        }
    }


}
