.case-studies {
    padding: 20rem 0 0 0;
}

.case-study-container {
    height: auto;
    width: 80vw;
    margin: 0 auto;
    // border: 1px solid red;
    box-shadow: 0px 10px 64px 0px rgba(0, 0, 0, 0.1);
    border-radius: 1.2rem;
}

.case-study-header {
    height: 50rem;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 1.2rem;
    // backdrop-filter: blur($standardBlur);
}

.case-study-header-nav {
    position: relative;
    // border: 1px solid lime;
}

.header-nav-arrows {
    position: absolute;
    display: flex;
    flex-direction: row;
    gap: 60vw;
    width: 90%;
    left: 50%;
    top: 36rem;
    transform: translate(-50%, 0);
    // border: 1px solid yellow;

    .portfolio-nav-prev {
        transform: rotate(90deg);

        img {
            height: 4rem;
        }
    }

    .portfolio-nav-next {
        transform: rotate(270deg);

        img {
            height: 4rem;
        }
    }
}

.case-study-title {
    margin-left: 50%;

    h1 {
        text-shadow: 0 1px 18px rgba(24, 0, 80, 0.5);
    }
}

.case-study-description {
        width: 40vw;
        padding-right: 8rem;
        text-align: justify;

    .see-live-btn {
        a {
            font-family: $paragraphFont;
            font-weight: bold;
            font-size: 2rem;
            color: $primaryColor;
            transition: .3s all;
            text-decoration: none;
            background-color: $offBlack;
            padding: 1rem;
            border-radius: 1rem;
        }

        a:hover {
            color: $secondaryColor;
        }

        a:visited {
            color: $offWhite;
        }
    }
}

.project-scope-section {
    max-height: 80rem;
}

.project-visualization-pc {
    display: visible !important;
}

.project-visualization-mobile {
    display: none !important;
}

.tech-section {
    height: auto;
}

.design-section {
    height: auto;
}

.bright-section {
    background: $offWhite;
    display: flex;
    flex-direction: row;
    padding: 4rem 8rem;

    .case-study-design img {
        width: 40vw;
        max-width: unset;
        margin: 0 auto;
        top: 4rem;
        right: 4rem;
        border-radius: 2rem;
        box-shadow: 8px 8px 24px 0px rgba(40, 40, 60, 0.3);
    }

    .case-study-description {
        p {
            color: $offBlack;
            font-size: 2rem;
            line-height: 126%;
        }
    }

    .case-study-description-title {
        h1 {
            // font-size: 5rem;
        }
    }
}

.dark-section {
        background: #383250;
        display: flex;
        flex-direction: row;
        padding: 4rem 8rem;

        .case-study-description {
            p {
                color: $offWhite;
                font-size: 2.2rem;
            }
        }

        .case-study-description-title {
            h1 {
                font-size: 5rem;
            }
        }

        .tech-stack-logos {
            display: flex;
            flex-direction: column;
            padding-right: 8rem;

            .tech-stack-logos-row {
                display: flex;
                flex-direction: row;
            }

            img {
                width: 5vw;
                height: 5vw;
                filter: contrast(200%) grayscale(90%);
                transition: ease-in-out .3s;
                margin: 1rem;
            }

            img:hover {
                filter: unset;
            }

            a {
                background-color: rgb(51, 26, 93);
                margin-bottom: 1rem;
                border-radius: 1rem;
            }

            a:hover {
                color: $secondaryColor;
                background-color: inherit;
            }

            a:visited {
                color: $offWhite;
            }
        }
}

.color-palette-wrapper {
    width: 26vw;
    height: 20rem;
    // border: 1px solid blue;
    display: grid;
    grid-template-columns: repeat(1, fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 8px;
    grid-row-gap: 8px;

    .color-palette {
        border-radius: $border-radius;
        box-shadow: 8px 8px 24px 0px rgba(40, 40, 60, 0.08);
    }

    .color-palette-1 {
        grid-area: 1 / 1 / 2 / 2;
        background-color: #6ca95e;
    }

    .color-palette-2 {
        grid-area: 1 / 2 / 2 / 3;
        background-color: #fcfffc;
    }

    .color-palette-3 {
        grid-area: 2 / 1 / 3 / 2;
        background-color: #1d3218;
    }

    .color-palette-4 {
        grid-area: 2 / 2 / 3 / 3;
        background-color: #ebf840;
    }

    p {
        padding: 5rem 0 0 2rem;
        text-transform: uppercase;
        opacity: .6;
    }

    .color-palette-3 p {
        color: $offWhite;
    }
}

.typography-wrapper {
    padding: 6rem 0;
    width: 18vw;

    img {
        padding: 2rem 0;
        width: auto;
        opacity: .9;
    }

    .font-name {

    }
}

.mobile-section {
    display: none;
    height: auto;
    background-image: url("../img/portfolio/background_mobile_view.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.mobile-view {
    img {
        margin: 0 auto;
        height: 70vh;
    }
}

.mobile-background-decal {
    // display: none;
    // position: relative;
    // height: 0;
    // width: 40vw;
    // top: 0rem;
    // z-index: 0;
}

.mobile-background-decal p {
    font-family: 'Iceberg', cursive;
    font-size: 12vw !important;
    line-height: 70%;
    color: rgba(0, 0, 0, 0.07) !important;
    text-shadow: 0px 0px 20px rgba(40, 40, 70, 0.3);
}

.portfolio-navigation {
    width: inherit;
    height: 20rem;
    height: 0px;
    padding: 0;
}

.portfolio-nav-arrows {
    position: relative;
    display: flex;
    flex-direction: row;
    gap: 20vw;
    left: 50%;
    bottom: 18rem;
    transform: translate(-50%, 0);

    .portfolio-nav-prev {
        transform: rotate(90deg);

        img {
            height: 4rem;
        }
    }

    .portfolio-nav-next {
        transform: rotate(270deg);

        img {
            height: 4rem;
        }
    }
}

@media (max-width: 1400px) and (min-width: 1000px) {
    .project-scope-section {
        min-height: 100rem;
    }
}

@media (min-width: 1400px) {
    .portfolio-filler {
        height: 0rem;
    }
}

@media (min-width: 1600px) {
    .case-study-header {
        height: 60rem;
    }

    // .case-study-title {
    //     h1 {
    //         font-size: 5.8rem;
    //     }
    // }
    .portfolio-filler {
        height: 20rem;
    }
}

@media (min-width: 1800px) {
    .portfolio-filler {
        height: 44rem;
    }
}

@media (min-width: 2000px) {
    .portfolio-filler {
        height: 130rem;
    }

    .mobile-background-decal {
        margin-top: 30vh;
    }

    .bright-section {
        .case-study-design img {
            // width: 1000px;
            height: 3474px;
            width: 1000px;
            }
        }

    .dark-section {
        .tech-stack-logos {
            img {
                width: 10rem;
                height: 10rem;
                filter: contrast(200%) grayscale(90%);
                transition: ease-in-out .3s;
                margin: 1rem;
                }
            }
    }
}

@media (max-width: 1800px) {
    .mobile-background-decal {
        display: none;
    }
}

@media (max-width: 1200px) {
    .case-study-title {
        margin-left: 10%;
    }

    .portfolio-navigation {
        width: inherit;
        height: 10rem;
        padding: 0;
    }

    .portfolio-nav-arrows {
        position: absolute;
        display: flex;
        flex-direction: row;
        gap: 20vw;
        left: 50%;
        bottom: 7rem;
        transform: translate(-50%, 0);
    }
}

@media (max-width: 1000px) {
    .case-study-description {
        width: 70vw;
        padding-right: 1rem;
}

    .design-section {
        .case-study-description {
            width: 100%;
        }
    }

    .color-palette-wrapper {
        width: 70vw;
    }

    .case-study-container {
        width: 95vw;
    }

    .project-visualization-pc {
        display: none !important;
    }

    .project-visualization-mobile {
        display: inline !important;

        .case-study-design img {
            border-radius: $border-radius;
            margin: 8rem auto;
            width: 90%;
            box-shadow: 0px 10px 64px 0px rgba(104, 59, 239, 0.847);
        }
    }

    .dark-section {
        .tech-stack-logos {
            img {
                width: 6rem;
                height: 6rem;
                filter: contrast(200%) grayscale(90%);
                transition: ease-in-out .3s;
                margin: 1rem;
                }
            }
    }

    .typography-wrapper {
        width: 40vw;
    }

    .mobile-view {
        position: absolute;

        img {
            width: 120%;
        }
    }
}

@media (max-width: 768px) {
    .header-nav-arrows {
        gap: 40vw;
        margin: 1rem;
    }

    // .case-study-title {
    //     margin-left: 4rem;
    // }

    .bright-section {
        padding-left: 4rem;

        .case-study-description-title {
            h1 {
                font-size: 9vw;
            }
        }
    }

    .dark-section {
        padding-left: 4rem;

        .case-study-description-title {
            h1 {
                font-size: 9vw;
            }
        }
    }

    .leading-text {
        max-width: 80vw;
        padding-top: 14rem;
    }

    .case-study-header {
        background-position: 25%;
        background-size: cover;
    }
}
