.section-services {
    background: $offWhite;
    will-change: transform, opacity;
}

// -------------------------- //
// grid layout
// -------------------------- //

.grid-parent {
    display: grid;
    grid-template-columns: repeat(3, 3fr);
    grid-template-rows: repeat(3, 3fr);
    grid-column-gap: 2rem;
    grid-row-gap: 2rem;
    // height: 700px;
    padding: 0 0 2rem 0;
    max-width: 768px;
}

    .div1 { grid-area: 1 / 1 / 3 / 2; }
    .div2 { grid-area: 3 / 1 / 4 / 2; }
    .div3 { grid-area: 1 / 2 / 2 / 3; }
    .div4 { grid-area: 2 / 2 / 4 / 3; }
    .div5 { grid-area: 1 / 3 / 2 / 4; }
    .div6 { grid-area: 2 / 3 / 3 / 4; }
    .div7 { grid-area: 3 / 3 / 4 / 4; }

// -------------------------- //
// component: feature tiles
// -------------------------- //

.tile {
    background-color: $primaryColor;
    display: flex;
    flex-direction: column;
    background-size: cover;
    opacity: .8;
    transition: all .2s ease-in-out;
    cursor: default;
    border-radius: $border-radius;
    height: auto;

    h2 {
        height: 20%;
        // width: 100%;
        color: $offWhite;
        font-size: 1.9rem;
        font-weight: bold;
        display: flex;
        font-family: $headingFont;
        justify-content: center;
        margin: 0 0 2rem 0;
        align-items: flex-end;
        // text-shadow: 0px 0px 6px $secondaryColor;
    }

    p {
        height: 80%;
        // width: 100%;
        font-size: 1.6rem;
        font-weight: bold;
        padding: 1.4rem;
        text-align: center;
        opacity: .85;
        display: flex;
        justify-content: center;
        align-items: flex-end;
        text-shadow: 0px 0px 6px $offBlack;
    }
}

.tile:hover {
    transform: scale(1.02);
    opacity: 1;

    h2 {
        color: $offWhite;
    }

    p {
        color: $offWhite;
        opacity: 1;
    }
}

.square-tile {
    // width: 100%;
    min-height: 220px;
    // max-height: 200px;
    // border-radius: $border-radius;
}

.vertical-tile {
    // width: 100%;
    // min-height: 280px;
}

// -------------------------- //
// feature tile images
// -------------------------- //

.div1 {
    background-image: linear-gradient(to bottom, $gradientSecondaryStart, $gradientSecondaryEnd), url("../img/tile_1.jpeg");
}

.div2 {
    background-image: linear-gradient(to bottom, $gradientSecondaryStart, $gradientSecondaryEnd), url("../img/tile_2.jpeg");
}

.div3 {
    background-image: linear-gradient(to bottom, $gradientSecondaryStart, $gradientSecondaryEnd), url("../img/tile_3.jpeg");
}

.div4 {
    background-image: linear-gradient(to bottom, $gradientSecondaryStart, $gradientSecondaryEnd), url("../img/tile_4.jpeg");
}

.div5 {
    background-image: linear-gradient(to bottom, $gradientSecondaryStart, $gradientSecondaryEnd), url("../img/tile_5.jpeg");
}

.div6 {
    background-image: linear-gradient(to bottom, $gradientSecondaryStart, $gradientSecondaryEnd), url("../img/tile_6.jpeg");
}

.div7 {
    background-image: linear-gradient(to bottom, $gradientSecondaryStart, $gradientSecondaryEnd), url("../img/tile_7.jpeg");
}

// -------------------------- //

@media (max-width: 1200px) {
    .tile {

        p {
            opacity: .9;
            text-shadow: 0 0px 4px rgba(0, 0, 0, 0.5);
        }
    }
}

@media (max-width: 1000px) {
    .background-pattern {
        display: none;
    }

    .grid-parent {
        display: grid;
        grid-template-columns: repeat(2, 2fr);
        grid-template-rows: repeat(5, 1fr);
        grid-column-gap: 2rem;
        grid-row-gap: 2rem;
    }

        .div1 { grid-area: 1 / 1 / 3 / 2; }
        .div2 { grid-area: 3 / 1 / 4 / 2; }
        .div3 { grid-area: 1 / 2 / 2 / 3; }
        .div4 { grid-area: 2 / 2 / 4 / 3; }
        .div5 { grid-area: 4 / 1 / 5 / 2; }
        .div6 { grid-area: 4 / 2 / 5 / 3; }
        .div7 { grid-area: 5 / 1 / 6 / 2; }

    .square-tile {
        height: 210px;
        border-radius: $border-radius;
    }

    .vertical-tile {
        height: 440px;
        border-radius: $border-radius;
    }

}


@media (max-width: 768px) {
    .tiles-container {
        padding: 0rem;
    }

    .grid-parent {
        display: flex;
        flex-direction: column;
    }

    .tile {
        opacity: .9;

        h2 {
            font-size: 2.6rem;
            color: $primaryColor;
            font-family: $headingFont;
            font-weight: 100;
        }

        p {
            font-size: 2.2rem;
            width: 20ch;
            margin: 0 auto;
            color: $offWhite;
        }

        h2, p {
            text-shadow: 2px 2px 32px $offBlack;
        }
    }

    .square-tile, .vertical-tile {
        width: 82vw;
        height: 300px;
    }
}
