.navbar {
    width: 100vw;
    position: fixed;
    z-index: 10;
    padding: 6rem 3rem 3rem 6rem;
    transition: ease-in-out .5s;
    will-change: transform, opacity;

    .nav-contents {
        display: flex;
        font-family: 'Nunito', sans-serif;

        .nav-header {
            font-family: 'Iceberg', cursive;
            font-size: 2.4rem;

            a {
                text-decoration: none;
                display: flex;
                flex-direction: row;

                .logo-left {
                    color: $primaryColor;
                }

                .logo-right {
                    color: $primaryColor;
                }
            }
        }
        
        .nav-links {
            list-style-type: none;
            display: flex;
            margin: 0 auto;
            padding-top: 3px;
            cursor: pointer;
        }

        .nav-item {
            list-style-type: none;
        }

        .nav-item a {
            color: $offWhite;
            padding: 0 20px 0 28px;
            font-size: 2rem;
            text-decoration: none;
            animation: fadeIn 500ms ease-in-out forwards;
            transform-origin: top center;
            transition: 0.3s;
            list-style-type: none;
        }

        .nav-item:hover a{
            color: $primaryColor;
            padding: 0px 24px 0px 24px;
        }

        .nav-item-phone {
            margin: 5px 0 0;
        }

        .language-select {
            width: 80px;
            padding-top: 4px;

            a {
                font-size: 2rem;
                text-decoration: none;
                color: $offWhite;
            }
        }

        .cta-btn {
            font-weight: bold;
            background-color: inherit;
            color: $primaryColor;
            font-size: 2rem;
            min-width: 180px;
            height: 36px;
            border: 0;
            outline: 2px solid $primaryColor;
            border-radius: 8px;

            a {
                text-decoration: none;
                color: $offWhite;
            }
        }

        button:hover {
            box-shadow: 0px 0px 14px $primaryColor;
            transition: .3s;
        }

        button:hover a {
            color: $primaryColor;
            cursor: pointer;
        }
    }
}

.navbar-initial {
    background: linear-gradient(180deg, rgb(7, 10, 17) 0%, rgba(0,0,0,0) 100%);
}

.navbar-alt {
    padding-top: 3rem;
    background-color:rgba($offBlack, 0.7);
    backdrop-filter: blur($standardBlur);
}

.nav-toggle {
    visibility: hidden;
    color: $offWhite;
    text-shadow: 0px 1px 10px $secondaryColor;
}

.nav-toggle:hover {
    outline: 4px solid $primaryColor;
    transform: rotate(180deg);
}

.nav-toggle i {
    font-size: 2.8rem;
}

.nav-lang-dropdown {
    margin-right: 2vw;
}
  
.nav-lang-dropdown .dropbtn {
    width: 80px;
    font-size: 2rem; 
    border: none;
    outline: none;
    color: $offWhite;
    padding: 3px 0;
    margin: 0 auto;
    background-color: inherit;
    font-family: inherit;
    border-left: 2px solid $primaryColor;
    cursor: pointer;    
    transition: ease-in-out .3s;
}
  
.dropdown-content {
    display: none;
    position: absolute;
    width: 80px;
    background-color: rgba(50, 0, 150, .9);
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
    padding: 3px 0 0 24px;
}
  
.dropdown-content a {
    float: none;
    color: $offWhite;
    font-size: 2rem;
    text-decoration: none;
    display: block;
    // text-align: left;
}
  
.topnav a:hover, .nav-lang-dropdown:hover .dropbtn {
    background-color: rgba(50, 0, 150, .7);
    color: $offWhite;
}
  
.dropdown-content a:hover {
    background-color: rgba(50, 0, 150, 1);
    color: $primaryColor;
}
  
.nav-lang-dropdown:hover .dropdown-content {
    display: block;
}

.dropdown-content:hover {
    border-left: 2px solid $primaryColor;
    font-weight: bold;
}

.hide-container {
    visibility: hidden;
}

.show-container {
    visibility: visible;
}

.modal-overlay {
    background-color:rgba($secondaryColor, 0.75);
    backdrop-filter: blur($standardBlur);
    position: absolute;
    width: 100vw;
    height: 100vh;
    margin: 0;
    padding: 0;
    z-index: 10;
}

.mobile-logo {
    position: absolute;
    visibility: visible;
    right: 70px;
    top: 70px;
    font-family: 'Iceberg', cursive;
    font-size: 3rem;
    text-decoration: none;
    color: $primaryColor;
    text-shadow: 0px 0px 44px rgba(0, 252, 8, 0.7);

    a {
        text-decoration: none;
        display: flex;
        flex-direction: row;
    }
}

.nav-mobile-language-select {
    display: none;
}

.nav-mobile-language-select a {
    font-size: 26px !important;

}

@media (max-width: 1200px) {
    .navbar {
        .nav-contents {
            .nav-item a {
                padding: 0px 10px 0px 12px;
            }
        
            .nav-item:hover a {
                padding: 0px 11px 0px 11px;
            }
        }
    }

    .nav-item-phone {
        display: none;
    }
}


@media (max-width: 768px) {
    .navbar {
        visibility: hidden;
        align-items: left;
        padding: 0;
    }

    .navbar-alt {
        backdrop-filter: none;
    }

    .nav-contents {
        .nav-header {
            display: none;
        }

        .nav-links {
            visibility: hidden;
            color: $offBlack;
            font-family: $headingFont;
            flex-direction: column;
            align-items: left;
            position: fixed;
            margin: 0;
            top: 24vh;

            .nav-item {
                margin-bottom: 3vh;
                width: 80vw;
                text-align: left;
            }
            
            .nav-item a {
                // border: 1px solid red;
                color: $offBlack;
                font-size: 8vw;
                transition: .3s;
                padding: 2rem;
            }

            .nav-item:hover a {
                background-color: $offBlack;
            }
        }
    }

    .nav-toggle {
        color: $offWhite;
        visibility: visible;
        border: 0px;
        background-color: inherit;
        cursor: pointer;
        transition: 0.8s;
        position: absolute;
        top: 70px;
        left: 50px;
         
        i {
        font-size: 6rem;
        }
    }

    .cta-btn {
        display: none;
    }

    .nav-mobile-language-select {
        display: block;
        a {
            // font-size: 10px;
        }
    }
}


@media (min-width: 769px) {
    .mobile-logo {
        visibility: hidden;
    }
}

@keyframes fadeIn {
    0% {
      opacity: 0;
      transform: rotateX(-90deg);
    }
    50% {
      transform: rotateX(-20deg);
    }
    100% {
      opacity: 1;
      transform: rotateX(0deg);
    }
}
