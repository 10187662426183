.section-why-go-online {
    background: url("../img/bkg_why_online.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    will-change: transform;
    text-shadow: 0px 0px 28px $offBlack;


    h2 {
        font-size: 2.4rem;
        letter-spacing: -.1rem;
    }

    li {
        font-size: 1.8rem;
        list-style-type: none;
    }

    strong {
        color: $primaryColor;
        font-weight: normal;
        }
}


.section-why-go-online .background-glass {
    border: 3px solid rgba($offBlack, 0.1);
    padding: 4rem;

    .fa-caret-right {
        margin-right: 1rem;
    }
}


@media (max-width: 768px) {
    .section-why-go-online {
        padding: 0;
    }

    .section-why-go-online .background-glass {
        max-width: 90vw;
        padding: 2rem;

        h2, li {
            max-width: 60vw;
            padding-bottom: 2rem;
        }
    }
}