.section-contact-form {
    background: url("../img/bkg_contact-form.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 0rem 0 12rem 0;
    box-sizing: border-box;
    height: auto;

    .send-btn {
        color: $offBlack;
        background-color: $primaryColor;
        font-size: 2rem;
        font-weight: bold;
        border: none;
        border-radius: $border-radius;
        padding: 1rem 3rem;
        margin: 0 0 1rem 1rem;
        cursor: pointer;
    }
}

input, select, textarea {
    all: unset;
    color: $offWhite;
    border-left: 4px solid $primaryColor;
    padding: 1rem 2rem 1rem;
    margin: 1rem 0;
    font-size: 1.4rem;
    border-radius: 4px;
}

input[type=text]:focus, input[type=email]:focus, textarea:focus {
    background: rgb(16, 16, 120);
    transition: .3s ease-in-out;
    outline: 1px solid rgba(rgb(100, 160, 170), 1);
}

textarea {
    min-height: 12rem;
}

::placeholder {
    color: rgba(100, 200, 160, 1);
    font-size: 1.3em;
}

.contact-methods {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 2rem;
    grid-row-gap: 2rem;
}

.form-control {
    height: 3rem;
    width: 80%;
    background: rgb(0, 5, 20);
    margin-left: 1rem;
}

.contact1 { grid-area: 1 / 1 / 3 / 2; }
.contact2 { grid-area: 1 / 2 / 2 / 3; }
.contact3 { grid-area: 2 / 2 / 3 / 3; }

.contact2, .contact3 {
    padding: 3rem;
}

.contact1, .contact2, .contact3 {
    border: 2px solid rgba(200, 50, 255, 0.1);
}

h2 {
    color: $primaryColor;
    font-size: 2.2rem;
}

h2 a {
    color: $primaryColor;
}

.contact2, .contact3 {
    p {
        font-family: $headingFont;
        font-size: 2.8rem;
    }

    a {
        color: $offWhite;
    }

    img {
        width: 260px
    }
}

@media (max-width: 1000px) {
    .contact-methods {
        display: flex;
        flex-direction: column;
        // max-width: 80vw;
    }
}

@media (max-width: 768px) {
    .section-contact-form {
        padding: 0 0 6rem 0;
    }

    .contact-methods {
        display: flex;
        flex-direction: column;
    }

    .contact2, .contact3 {
        h2 {
            font-size: 2rem;
        }

        p, a {
            font-size: 2.4rem;
        }
    }
}