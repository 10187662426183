$slider-width: 360px;
$slider-height: 680px;

.section-portfolio {
    background: $offBlack;
    height: calc($slider-height+100px);
    will-change: transform;

}

.portfolio-container {
    margin: 0 auto;
    width: 50vw;
    height: auto;
}

.slider {
    position: relative;
    top: 300px;
    left: 50%;
    transform: translate(-50%, -50%);
    width: $slider-width;
    height: $slider-height;

    a {
        text-decoration: none;
    }
}

.wrapper {
    overflow: hidden;
    position: relative;
    width: $slider-width;
    height: $slider-height;
}

.slides {
    display: flex;
    position: relative;
    top: 0;
    left: -$slider-width;
    width: 10000px;
}

.slides.shifting {
    transition: left .2s ease-out;
}

.slide {
    width: $slider-width;
    height: $slider-height;
    display: flex;
    flex-direction: column;
    justify-content: center;
    transition: all .7s;
    position: relative;
    opacity: .8;

    h2 {
        position: relative;
        bottom: 12rem;
        color: $offBlack;
        font-size: 2.1rem;
        text-align: center;
        transition: all .7s;
        padding: 0 30px 0;
        text-shadow: 0px 1px 8px rgba(255, 255, 255, 0.2);
    }
}

.slide:hover {
    opacity: 1;

    h2 {
        color: $secondaryColor;
    }
}

.slider.loaded {
    .slide:nth-child(2),
    .slide:nth-child(7),
    .slide:nth-child(1),
    .slide:nth-child(6),
    .slide:nth-child(3),
    .slide:nth-child(4),
    .slide:nth-child(5) { background: #ffffff00 }
}

.control {
    position: absolute;
    top: 50%;
    width: 140px;
    height: 140px;
    z-index: 2;
}

.prev,
.next {
    background-position: center;
    background-repeat: no-repeat;
    cursor: pointer;
    transition: $transition-default;
}

.prev {
    background-image: url("../img/arrow.svg");
    background-size: contain;
    left: -130px;
    transform: rotate(90deg);
}

.next {
    background-image: url("../img/arrow.svg");
    background-size: contain;
    right: -130px;
    transform: rotate(270deg);
}

.prev:hover {
    left: -140px;
}

.next:hover {
    right: -140px;
}


@media (max-width: 1200px) {
    .portfolio-container {
        width: 70vw;
    }

    .slide {
        h2 {
            // color: red;
        }
    }

}

@media (max-width: 1000px) {
    .portfolio-container {
        width: 80vw;
    }
}

@media (max-width: 500px) {
    .portfolio-container {
        width: 100vw;
    }

    .slide {

        h2 {
            font-size: 3rem;
            // bottom: 16rem;
        }
    }

    // .control {
    //     width: 140px;
    //     height: 140px;
    // }

    .prev {
        left: -10px;
        transform: rotate(90deg);
    }

    .next {
        right: -10px;
        transform: rotate(270deg);
    }

    .prev:hover {
        left: -16px;
    }

    .next:hover {
        right: -16px;
    }
}