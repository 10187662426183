// -------------------------- //
// main stylesheet
// -------------------------- //

@import

'./components/css-reset.scss', // Josh's Custom CSS Reset: https://www.joshwcomeau.com/css/custom-css-reset/
'./components/variables.scss', // global style variables
'./components/intro.scss', // site preloader
'./components/navbar.scss', // navbar navigation
'./components/sprites.scss', // sprites rendered dynamically in main page's sides
'./components/cookies.scss', //cookie consent info container

// main page sections
'./sections/hero.scss',
'./sections/services.scss',
'./sections/pitch.scss',
'./sections/workflow.scss',
'./sections/portfolio.scss',
'./sections/call-to-action.scss',
'./sections/contact-form.scss',
'./sections/footer.scss',

// thank you page (after submitting contact form)
'./sections/contact-thank-you.scss',

// privacy policy
'./components/privacy-policy',

// case study page template
'./sections/case-studies.scss',

// locomotive scroll
'../../node_modules/locomotive-scroll/src/styles/_base.scss',
'../../node_modules/locomotive-scroll/src/styles/_scrollbar.scss';

* {
    // outline: 1px solid magenta !important;
//     // visibility: visible !important;
//     // opacity: 1 !important;
}

html {
    font-size: 62.5%;
}

body {
    background: $offBlack;
    color: $offWhite;
    font-family: $paragraphFont;
    margin: 0;
    padding: 0;
    overflow-x: hidden;
}

.section-container {
    margin: 0 auto;
    padding: 0 0 3rem 0;
    width: 50vw;
    max-width: 160rem;
}

.rounded-corners {
    border-radius: $border-radius;
}

.heading-font {
    font-family: $headingFont;
    font-size: 4.8rem;
    line-height: 90%;
    letter-spacing: -.16rem ;
    max-width: 90vw;
    font-weight: normal;

    strong {
        color: $primaryColor;
        font-weight: normal;
    }
}

.heading-dark {
    color: $offBlack;

    strong {
        color: $secondaryColor;
        font-weight: normal;
    }
}

.leading-text {
    display: flex;
    padding: 8rem 0rem 6rem 0rem;
    max-width: 90ch;

    .accent {
        min-width: 1rem;
        border-radius: $border-radius;
        margin-right: 4rem;
    }

    .accent-primary {
        background-color: $primaryColor;
    }

    .accent-secondary {
        background-color: $secondaryColor;
    }
}

.background-glass {
    background-color: rgba($secondaryColor, .2);
    backdrop-filter: blur($standardBlur);
}

.custom-fade-in {
    opacity: 1;
    transition: opacity 1s;
}

.custom-fade-out {
    opacity: 0;
    transition: opacity 1s;
}

.scroll-class {
    opacity: 1;
}

@media (max-width: 1200px) {
    .section-container {
        padding: 0 0 4rem 2rem;
        width: 80vw;
    }

    .pattern {
        display: none;
    }

    .leading-text {
        max-width: 70vw;
    }
}

@media (max-width: 768px) {
    .section-container {
        padding: 0;
    }

    .heading-font {
        font-size: 4rem;
        line-height: 1;
    }
}

